export const REMOVE_WISHLIST_ITEM_REQUEST = 'REMOVE_WISHLIST_ITEM_REQUEST';
export const REMOVE_WISHLIST_ITEM_SUCCESS = 'REMOVE_WISHLIST_ITEM_SUCCESS';
export const REMOVE_WISHLIST_ITEM_FAIL = 'REMOVE_WISHLIST_ITEM_FAIL';

export const ADD_WISHLIST_ITEM_REQUEST = 'ADD_WISHLIST_ITEM_REQUEST';
export const ADD_WISHLIST_ITEM_SUCCESS = 'ADD_WISHLIST_ITEM_SUCCESS';
export const ADD_WISHLIST_ITEM_FAIL = 'ADD_WISHLIST_ITEM_FAIL';

export const GET_WISHLIST_REQUEST = 'GET_WISHLIST_REQUEST';
export const GET_WISHLIST_SUCCESS = 'GET_WISHLIST_SUCCESS';
export const GET_WISHLIST_FAIL = 'GET_WISHLIST_FAIL';

interface RemoveWishlistItemRequest {
  type: typeof REMOVE_WISHLIST_ITEM_REQUEST;
  payload: {};
}

interface RemoveWishlistItemSuccess {
  type: typeof REMOVE_WISHLIST_ITEM_SUCCESS;
  payload: {
    id: string;
  };
}

interface RemoveWishListItemFail {
  type: typeof REMOVE_WISHLIST_ITEM_FAIL;
  payload: {
    error?: string;
  };
}

interface AddWishListItemRequest {
  type: typeof ADD_WISHLIST_ITEM_REQUEST;
  payload: {};
}

interface AddWishListItemSuccess {
  type: typeof ADD_WISHLIST_ITEM_SUCCESS;
  payload: {
    id: string;
  };
}

interface AddWishListItemFail {
  type: typeof ADD_WISHLIST_ITEM_FAIL;
  payload: {
    error?: string;
  };
}

interface GetWishListRequest {
  type: typeof GET_WISHLIST_REQUEST;
  payload: {};
}
interface GetWishListSuccess {
  type: typeof GET_WISHLIST_SUCCESS;
  payload: {
    list: string[];
  };
}
interface GetWishListFail {
  type: typeof GET_WISHLIST_FAIL;
  payload: {
    error?: string;
  };
}

export type WishlistActionTypes =
  | AddWishListItemRequest
  | AddWishListItemSuccess
  | AddWishListItemFail
  | RemoveWishlistItemRequest
  | RemoveWishlistItemSuccess
  | RemoveWishListItemFail
  | GetWishListRequest
  | GetWishListSuccess
  | GetWishListFail;

export interface WishlistState {
  list: string[];
  error: string;
}
