import {
  AuthActionTypes,
  AuthState,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  RESET_AUTH_ERRORS,
  SIGNUP_FAIL,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  VERVIY_EMAIL,
} from '../types/auth';

const initState: AuthState = {
  uid: '',
  token: '',
  email: '',
  emailVerified: false,
  error: {
    email: '',
    password: '',
    user: '',
  },
};

const reducer = (state = initState, action: AuthActionTypes): AuthState => {
  if (action.type === VERVIY_EMAIL) {
    return { ...state, emailVerified: action.payload.verfied };
  } else if (action.type === LOGIN_REQUEST) {
    return {
      ...state,
    };
  } else if (action.type === LOGIN_SUCCESS) {
    const { email, uid, emailVerified } = action.payload;
    return {
      ...state,
      email,
      uid,
      emailVerified,
      error: {
        email: '',
        password: '',
        user: '',
      },
    };
  } else if (action.type === LOGIN_FAIL) {
    const { email, password, user } = action.payload;

    return {
      ...state,
      error: {
        email,
        password,
        user,
      },
    };
  } else if (action.type === SIGNUP_REQUEST) {
    return {
      ...state,
    };
  } else if (action.type === SIGNUP_SUCCESS) {
    const { uid } = action.payload;
    return {
      ...state,
      uid,
      error: {
        user: '',
        email: '',
        password: '',
      },
    };
  } else if (action.type === SIGNUP_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === RESET_AUTH_ERRORS) {
    return {
      ...state,
      error: {
        user: '',
        email: '',
        password: '',
      },
    };
  } else return state;
};

export default reducer;
