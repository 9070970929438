import React, { useState, createContext, useEffect } from 'react';
import firebase from 'firebase/app'; // Import firebase for firestore
export const productsC = firebase.firestore().collection('Products');

type ContextValueType = {
  newProducts: any[] | null; // Corrected property name
};

export const ProductContext = createContext<ContextValueType>({ newProducts: null });

const ProductContextProvider: React.FC = ({ children }) => {
  const [newProducts, setNewProducts] = useState<any[] | null>(null); // Corrected property name

function newestToOldest(a: any, b: any) {
  const titleA = a?.title?.toLowerCase();
  const titleB = b?.title?.toLowerCase();

  // Check if both products have a title
  if (titleA && titleB) {
    // Compare the titles
    if (titleA < titleB) return -1; // Title A comes before Title B
    if (titleA > titleB) return 1; // Title A comes after Title B
    return 0; // Titles are equal
  }
  
  // If one of the products doesn't have a title, keep it at its current position
  return 0;
}

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const snapshot = await productsC.get();
        const productData = snapshot.docs.map(doc => doc.data());
        productData.sort(newestToOldest);
        setNewProducts(productData);
      } catch (error) {
        console.error('Error fetching products:', error);
        setNewProducts([]); // Set to empty array in case of error
      }
    };

    fetchProducts();

    // Cleanup function if needed
    return () => {
      // Cleanup logic if needed
    };
  }, []);

  return (
    <ProductContext.Provider
      value={{
        newProducts,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductContextProvider;
