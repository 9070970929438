import React, { useState, createContext, useEffect } from 'react';
import { categoriesC } from '../utils/firebase-utils';

type ContextValueType = {
  newCategories: any[];
};

export const CategoriesContext = createContext<ContextValueType>({} as any);

const CategoriesContextProvider: React.FC = ({ children }) => {
  const [newCategories, setNewCategories] = useState<any[]>([]);

  function newestToOldest(a: any, b: any) {
    if (a.index === b.index) {
      const nameA = a?.name?.toLowerCase();
      const nameB = b?.name?.toLowerCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    }
    return a?.index - b?.index;
  }

  useEffect(() => {
    const arr: any = [];
    const ref = categoriesC
      .get()
      .then(res => {
        if (res.size > 0) {
          res.docs.map(doc => {
            arr.push(doc.data());
          });
          arr.sort(newestToOldest);
          // const filteredArr = arr?.filter((c: any) => c?.disabled ===  false);

          setNewCategories(arr);
        }
      })
      .catch(err => console.log(err));
  }, []);

  return (
    <CategoriesContext.Provider
      value={{
        newCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export default CategoriesContextProvider;
