import { Product, productConverter } from '../../models/[new]product';
import { productsC } from '../../utils/firebase-utils';
import { splitTo10s, flatWhereIn10s } from '../../utils/firestoreHelpers';
import { AppState } from '../root-reducer';
import { AppThunk } from '../store';
import {
  ActionTypes,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
} from '../types/products';

export const getProductsRequest = (): ActionTypes => {
  return {
    type: GET_PRODUCTS_REQUEST,
    payload: {},
  };
};
export const getProductsSuccess = ({
  list,
}: {
  list: Product[];
}): ActionTypes => {
  return {
    type: GET_PRODUCTS_SUCCESS,
    payload: { list },
  };
};

export const getProductsFail = ({ error }: { error: string }): ActionTypes => {
  return {
    type: GET_PRODUCTS_FAIL,
    payload: { error },
  };
};

export const getProductsThunk = ({ ids }: { ids: string[] }): AppThunk => {
  return async dispatch => {
    dispatch(getProductsRequest());
    try {
      const splited = splitTo10s(ids);
      const data = await Promise.all(
        splited.map(substet =>
          productsC
            .withConverter(productConverter)
            .where('id', 'in', substet)
            .get(),
        ),
      );
      const normalizedData = flatWhereIn10s(data) as Product[];
      dispatch(getProductsSuccess({ list: normalizedData }));
    } catch (e) {
      dispatch(getProductsFail({ error: e.message || e.code }));
    }
  };
};

export const getCartProductsSelector = (state: AppState) => {
  const {
    cart: { cart },
    products: { list: products },
  } = state;

  const data = cart
    .map(item => {
      const proData = products.find(pro => pro.id === item.id);
      if (!proData) return undefined;
      return { ...proData, ...item };
    })
    .filter(e => !!e);
  return data;
};
