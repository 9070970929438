import {
  SelectProductIdActionTypes,
  SELECT_PRODUCT_ID_REQUEST,
  SELECT_PRODUCT_ID_SUCCESS,
  SELECT_PRODUCT_ID_FAIL,
} from '../types/selectproductid';

interface SelectProductIdState {
  selectedProductId: string | null;
}

const initialState: SelectProductIdState = {
  selectedProductId: null,
};

const selectedProductIdReducer = (
  state = initialState,
  action: SelectProductIdActionTypes,
): SelectProductIdState => {
  switch (action.type) {
    case SELECT_PRODUCT_ID_REQUEST:
      // Handle request action
      return state;
    case SELECT_PRODUCT_ID_SUCCESS:
      // Handle success action
      return {
        ...state,
        selectedProductId: action.payload.productId,
      };
    case SELECT_PRODUCT_ID_FAIL:
      // Handle failure action
      return state;
    default:
      return state;
  }
};

export default selectedProductIdReducer;
