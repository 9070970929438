import axios from 'axios';
import PayPal from '../../models/[new]paypal';
import CreditCard, { BillingAddress as BA } from '../../models/credit-card';
import { Something_Went_Wrong } from '../../utils/constants';
import {
  baseURL,
  generateUserToken,
  privateUserSourcesC,
  userPaypalAccount,
  auth,
} from '../../utils/firebase-utils';
import { AppThunk } from '../store';
import { setSelectedBillingAddressId } from './checkout-page';

import {
  ActionTypes,
  ADD_CARD_FAIL,
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  ADD_PAYPAL_FAIL,
  ADD_PAYPAL_REQUEST,
  ADD_PAYPAL_SUCCESS,
  DELETE_CARD_FAIL,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  GET_CARDS_FAIL,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS,
  GET_PAYPAL_FAIL,
  GET_PAYPAL_REQUEST,
  GET_PAYPAL_SUCCESS,
  DELETE_PAYPAL_REQUEST,
  DELETE_PAYPAL_FAIL,
  DELETE_PAYPAL_SUCCESS,
} from '../types/payment';
import { setSelectedPaymentMethod } from './checkout-page';
import Address from '../../models/[new]address';

//  todo delete paypal accounts, later

export const addPaypalRequest = (): ActionTypes => {
  return {
    type: ADD_PAYPAL_REQUEST,
    payload: {},
  };
};

export const addPaypalSuccess = ({
  account,
}: {
  account: PayPal;
}): ActionTypes => {
  return {
    type: ADD_PAYPAL_SUCCESS,
    payload: {
      account,
    },
  };
};

export const addPaypalFail = ({ error }: { error: string }): ActionTypes => {
  return {
    type: ADD_PAYPAL_FAIL,
    payload: {
      error,
    },
  };
};

export const deletePaypalRequest = (): ActionTypes => {
  return {
    type: DELETE_PAYPAL_REQUEST,
    payload: {},
  };
};

export const deletePaypalSuccess = (): ActionTypes => {
  return {
    type: DELETE_PAYPAL_SUCCESS,
    payload: {},
  };
};
export const deletePaypalFail = ({ error }: { error: string }): ActionTypes => {
  return {
    type: DELETE_PAYPAL_FAIL,
    payload: {
      error,
    },
  };
};

export const getPaypalRequest = (): ActionTypes => {
  return {
    type: GET_PAYPAL_REQUEST,
    payload: {},
  };
};

export const GetPaypalSuccess = ({
  account,
}: {
  account: PayPal;
}): ActionTypes => {
  return {
    type: GET_PAYPAL_SUCCESS,
    payload: {
      account,
    },
  };
};

export const getPaypalFail = ({ error }: { error: string }): ActionTypes => {
  return {
    type: GET_PAYPAL_FAIL,
    payload: {
      error,
    },
  };
};

export const addCardRequest = (): ActionTypes => {
  return {
    type: ADD_CARD_REQUEST,
    payload: {},
  };
};

export const addCardSuccess = ({ card }: { card: CreditCard }): ActionTypes => {
  return {
    type: ADD_CARD_SUCCESS,
    payload: {
      card,
    },
  };
};

export const addCardFail = ({ error }: { error: string }): ActionTypes => {
  return {
    type: ADD_CARD_FAIL,
    payload: {
      error,
    },
  };
};

export const deleteCardRequest = (): ActionTypes => {
  return {
    type: DELETE_CARD_REQUEST,
    payload: {},
  };
};

export const deleteCardSuccess = ({ id }: { id: string }): ActionTypes => {
  return {
    type: DELETE_CARD_SUCCESS,
    payload: {
      id,
    },
  };
};

export const deleteCardFail = ({
  error,
  id,
}: {
  error: string;
  id: string;
}): ActionTypes => {
  return {
    type: DELETE_CARD_FAIL,
    payload: {
      error,
      id,
    },
  };
};

export const getCardsRequest = (): ActionTypes => {
  return {
    type: GET_CARDS_REQUEST,
    payload: {},
  };
};

export const getCardsSuccess = ({
  cards,
}: {
  cards: CreditCard[];
}): ActionTypes => {
  return {
    type: GET_CARDS_SUCCESS,
    payload: { cards },
  };
};

export const getCardsFail = ({ error }: { error: string }): ActionTypes => {
  return {
    type: GET_CARDS_FAIL,
    payload: { error },
  };
};

export const addCardThunk = ({
  card,
  billingAddress,
  billingAddressId,
}: {
  card: CreditCard | null;
  billingAddress?: Address | undefined;
  billingAddressId?: String;
}): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(addCardRequest());
    const {
      auth: { uid, emailVerified },
      payment: {
        cards: { list },
      },
    } = getState();
    if (!auth?.currentUser?.emailVerified) {
      await auth?.currentUser?.reload();
      if (!auth?.currentUser?.emailVerified) {
        return auth?.currentUser
          ?.sendEmailVerification()
          .then(res => {
            return dispatch(
              addCardFail({ error: 'Please Verify Your Email to Complete' }),
            );
          })
          .catch(er => {
            console.log('error : ', er);
            return dispatch(
              addCardFail({ error: 'Please Verify Your Email to Complete' }),
            );
          });
      }
    }

    if (!card) return dispatch(addCardFail({ error: Something_Went_Wrong }));

    if (!billingAddress)
      return dispatch(addCardFail({ error: 'Please Add a billing address' }));

    try {
      const { fingerprint, token } = card;
      const userToken = await generateUserToken();
      axios
        .post(
          `${baseURL}/addPaymentSourceApi`,
          {
            token: token,
            billingAddressId: billingAddressId,
          },

          {
            headers: {
              Authorization: userToken,
            },
          },
        )
        .then(res => {
          if (list.find(item => item.fingerprint === fingerprint)) {
            dispatch(
              addCardFail({
                error: 'Card already exists',
              }),
            );
            dispatch(deleteCardFail({ error: '', id: '' }));
          } else {
            dispatch(
              setSelectedPaymentMethod({
                type: 'CARD',
                token: card?.card?.id,
                //@ts-ignore
                value: card?.card?.last4,
              }),
            );

            dispatch(
              setSelectedBillingAddressId({
                id: (billingAddressId as string) ?? '',
              }),
            );

            return dispatch(addCardSuccess({ card: card }));
          }
        })
        .catch(error => {
          let errorText = '';
          console.log('error: ', error);
          switch (error.response.data.code) {
            case 'card_declined':
              errorText = 'You card was declined';
              break;
            case 'customer_max_payment_methods':
              errorText = 'Maximum number of Payment Methods has been reached.';
              break;
            case 'expired_card':
              errorText =
                'The card has expired. Check the expiration date or use a different card.';
              break;
            case 'incorrect_address':
              errorText =
                'The card’s address is incorrect. Check the card’s address or use a different card.';
              break;
            case 'incorrect_cvc':
              errorText =
                'The card’s security code is incorrect. Check the card’s security code or use a different card.';
              break;
            case 'incorrect_number':
              errorText =
                'The card number is incorrect. Check the card’s number or use a different card.';
              break;
            case 'incorrect_zip':
              errorText =
                'The card’s ZIP code is incorrect. Check the card’s ZIP code or use a different card.';
              break;
            case 'processing_error':
              errorText =
                'The card charge is declined. Check the charge or use a different card';
              break;
            default:
              errorText = error.response.data.message;
          }
          dispatch(addCardFail({ error: errorText }));
          return dispatch(deleteCardFail({ error: '', id: '' }));
        });
    } catch (e) {
      // TODO, split to another file
      let error: string;
      if (e.code === 'HOSTED_FIELDS_FIELDS_EMPTY') {
        error = 'All fields are empty! Please fill out the form.';
      } else if (e.code === 'HOSTED_FIELDS_FIELDS_INVALID') {
        error = 'Some fields are invalid';
      } else if (e.code === 'HOSTED_FIELDS_TOKENIZATION_FAIL_ON_DUPLICATE') {
        error = 'This payment method already exists in your vault.';
      } else if (
        e.code === 'HOSTED_FIELDS_TOKENIZATION_CVV_VERIFICATION_FAILED'
      ) {
        error = 'CVV did not pass verification';
      } else if (e.code === 'HOSTED_FIELDS_FAILED_TOKENIZATION') {
        error = 'Tokenization failed server side. Is the card valid?';
      } else if (e.code === 'HOSTED_FIELDS_TOKENIZATION_NETWORK_ERROR') {
        error = 'Network error occurred when tokenizing.';
      } else error = Something_Went_Wrong;
      dispatch(
        addCardFail({
          error,
        }),
      );
      return dispatch(deleteCardFail({ error: '', id: '' }));
    }
  };
};

export const deleteCardThunk = ({ id }: { id: string }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(deleteCardRequest());
    const {
      auth: { uid, emailVerified },
      payment: {
        cards: { list },
      },
    } = getState();

    const cardToken = list.find(item => item.id === id)?.id;

    if (!cardToken)
      return dispatch(deleteCardFail({ error: Something_Went_Wrong, id }));

    try {
      const userToken = await generateUserToken();
      await axios.post(
        `${baseURL}/deletePaymentSourceApi`,
        {
          token: id,
        },
        {
          headers: {
            Authorization: userToken,
          },
        },
      );
      dispatch(deleteCardSuccess({ id }));
    } catch (e) {
      dispatch(
        deleteCardFail({ id, error: e.message || Something_Went_Wrong }),
      );
    }
  };
};

export const getCardsThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(getCardsRequest());
    try {
      const cardsRef = await privateUserSourcesC(uid).get();
      const formattedCards = cardsRef.docs.map(card => {
        return {
          id: card.id,
          ...card.data(),
        } as unknown as CreditCard;
      });
      dispatch(
        getCardsSuccess({
          cards: formattedCards,
        }),
      );
    } catch (e) {
      dispatch(
        getCardsFail({
          error: e.message || Something_Went_Wrong,
        }),
      );
    }
  };
};

export const addPaypalThunk = ({ nonce }: { nonce: any }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(addPaypalRequest());
    const {
      auth: { uid, emailVerified },
    } = getState();
    try {
      const token = await generateUserToken();

      if (!auth?.currentUser?.emailVerified) {
        await auth?.currentUser?.reload();
        if (!auth?.currentUser?.emailVerified) {
          return auth?.currentUser
            ?.sendEmailVerification()
            .then(res => {
              return dispatch(
                addPaypalFail({
                  error: 'Please Verify Your Email to Complete',
                }),
              );
            })
            .catch(er => {
              console.log('error : ', er);
              return dispatch(
                addPaypalFail({
                  error: 'Please Verify Your Email to Complete',
                }),
              );
            });
        }
      }

      await axios.post(
        `${baseURL}/addPaypalApi`,
        { nonce: nonce },
        {
          headers: {
            Authorization: token,
          },
        },
      );
      const paypalRef = await userPaypalAccount(uid).get();
      if (!paypalRef.exists)
        return dispatch(
          addPaypalFail({
            error: Something_Went_Wrong,
          }),
        );
      const account = new PayPal({
        id: paypalRef.id,
        ...paypalRef.data(),
      }).toJson();

      // await dispatch(addPaypalSuccess({ account }));
      // dispatch(
      //   setSelectedPaymentMethod({
      //     token: account.token ?? '',
      //     type: 'PAYPAL',
      //     value: account.email ?? ''
      //   }),
      // );
    } catch (error) {
      dispatch(addPaypalFail({ error: error.message || Something_Went_Wrong }));
    }
  };
};

export const getPaypalThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getPaypalRequest());
    const {
      auth: { uid },
    } = getState();
    try {
      const paypalRef = await userPaypalAccount(uid).get();
      if (!paypalRef.exists)
        return dispatch(
          getPaypalFail({
            error: Something_Went_Wrong,
          }),
        );
      const account = new PayPal({
        id: paypalRef.id,
        ...paypalRef.data(),
      }).toJson();

      dispatch(GetPaypalSuccess({ account }));
    } catch (e) {
      dispatch(getPaypalFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

export const deletePaypalThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(deletePaypalRequest());
    const {
      payment: {
        paypal: { account },
      },
    } = getState();

    if (!account) {
      return dispatch(
        deletePaypalFail({
          error: 'Account not found',
        }),
      );
    }

    const { token } = account;
    try {
      const userToken = await generateUserToken();
      await axios.post(
        `${baseURL}/unlinkPaypalApi`,
        {
          token,
          doc_id: 'account',
        },
        {
          headers: {
            authorization: userToken,
          },
        },
      );
      dispatch(deletePaypalSuccess());
    } catch (e) {
      dispatch(
        deletePaypalFail({
          error: e.message || Something_Went_Wrong,
        }),
      );
    }
  };
};
