import React from 'react';
import {
  TextField,
  TextFieldProps,
  makeStyles,
  createStyles,
  Theme,
  withStyles,
  fade,
  InputBase,
  InputBaseProps,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& input': {
        boxSizing: 'border-box',
        height: '48px !important',
      },
      '& .MuiInputBase-root': {
        boxSizing: 'border-box',
        height: '48px !important',
      },
    },
    error: {
      '& input': {
        borderColor: theme.palette.error.main,
        boxShadow: `${theme.palette.error.main} 0 0 0 0.2rem`,
      },
    },
  }),
);
export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.common.white,
      border: '1px solid #ced4da',
      fontSize: 16,
      width: 'auto',
      padding: '10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase);

type BootstrapInputProps = InputBaseProps & {
  label: string;
  htmlFor?: string;
};
export const SBootstrapInput = ({
  label,
  htmlFor,
  className,
  ...rest
}: BootstrapInputProps) => {
  const classes = useStyles();
  return (
    <FormControl>
      <InputLabel shrink htmlFor={htmlFor}>
        {label}
      </InputLabel>
      <BootstrapInput
        className={clsx(classes.root, rest.error && classes.error, className)}
        defaultValue="react-bootstrap"
        id={htmlFor}
        {...rest}
      />
    </FormControl>
  );
};

const CustomTextField = React.forwardRef(
  ({ className, ...rest }: TextFieldProps, ref: any,) => {
    const classes = useStyles(); 
      return (
        <TextField
          className={clsx(classes.root, className)}
     
          {...rest}
          ref={ref}
        />
      );
  },
);

export default CustomTextField;


