import { responsiveFontSizes } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles'
// #F5F3F4 red
const theme = createTheme({
  palette: {
    primary: {
      light: '#F5F3F4',
      main: '#4F3E42',
      dark: '#3e3135',
      '100': '#ededed',
      contrastText: '#fff',
    },
    secondary: {
      main: '#CF8A50',
      light: '#F9F7D0',
      dark: '#3e3135',
      100: '#ededed',
    },
    error: {
      main: '#B00020',
    },
  },
  typography: {
    // default material font size
    h1: {
      fontFamily: "'Poppins', sans-serif",
      letterSpacing: '0.72px',
      color: '#000000DE',
    },
    h2: {
      letterSpacing: '0.45px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    h3: {
      letterSpacing: '0.36px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    h4: {
      letterSpacing: '0.26px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    h5: {
      letterSpacing: '0.18px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    h6: {
      letterSpacing: '0.15px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    body1: {
      letterSpacing: '0.5px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    body2: {
      letterSpacing: '0.25px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    button: {
      letterSpacing: '1.25px',
    },
    subtitle1: {
      letterSpacing: '0.14px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    subtitle2: {
      letterSpacing: '0.1px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
    caption: {
      letterSpacing: '0.4px',
      color: '#000000DE',
      fontFamily: "'Poppins', sans-serif",
    },
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        borderRadius: '4px',
        height: '48px',
        color: '#FFF',
        background: '#F5F3F4',
      },
      containedSecondary: {
        borderRadius: '4px',
        height: '48px',
        color: '#FFF',
        background: '#F9F7D0',
      },
      outlinedPrimary: {
        borderRadius: '4px',
        border: '1px solid #0000001F',
        height: '48px',
      },
      outlinedSecondary: {
        borderRadius: '4px',
        border: '1px solid #0000001F',
        height: '48px',
      },
    },
  },
});

export default responsiveFontSizes(theme);
