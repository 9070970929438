import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

const CategoriesButton = () => {
  const history = useHistory();
  const navigateToCategories = () => history.push('/categories-collection');

  return (
    <Button
      onClick={navigateToCategories}
      variant="contained"
      color="secondary"
    >
      Browse Our Categories
    </Button>
  );
};

export default CategoriesButton;
