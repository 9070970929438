import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core';
import { GrApple } from 'react-icons/gr';
import { useDispatch } from 'react-redux';
import CustomButton, { Props as CustomButtonProps } from '../Button';
import { loginWithAppleThunk } from '../../../redux/actions/auth';
import { SignInWithApple } from '../../../utils/firebase-utils';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#000000d4',
      color: '#fff',
      width: '160px',
      height: '48px',
      '&:hover': {
        background: 'black',
      },
    },
  }),
);

const AppleLoginBtn = (props: CustomButtonProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loginWithApple = () => {
    // history.push('/login?auth=apple');
    try {
      // setLoading('appleLogin');
      SignInWithApple()
        .then(() => {
          // setLoading('');
        })
        .catch(err => {
          let errorMessage = err.message;
          // setError(errorMessage);
          // setLoading('');
        });
    } catch (e) {
      let errorMessage = e.message;
      // setError(errorMessage);
      // setLoading('');
    }
  };

  return (
    <CustomButton
      className={classes.root}
      startIcon={<GrApple color="white" />}
      variant="contained"
      {...props}
      onClick={() => dispatch(loginWithAppleThunk())}
      // onClick={loginWithApple}
    >
      Apple
    </CustomButton>
  );
};

export default AppleLoginBtn;
