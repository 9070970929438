import firebaseErrsHandler from '../../utils/firebaseAuthErrs';

export const LOGOUT_USER = 'LOGOUT_USER';
export const VERVIY_EMAIL = 'VERVIY_EMAIL';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';

export const SIGNUP_REQUEST = 'SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAIL = 'SIGNUP_FAIL';

export const RESET_AUTH_ERRORS = 'RESET_AUTH_ERRORS';

interface VerfiyEmailAction {
  type: typeof VERVIY_EMAIL;
  payload: {
    verfied: boolean;
  };
}

interface LogoutUserAction {
  type: typeof LOGOUT_USER;
  payload: {};
}

interface LoginUserAction {
  type: typeof LOGIN_REQUEST;
  payload: {};
}

interface LoginUserSuccessAction {
  type: typeof LOGIN_SUCCESS;
  payload: {
    uid: string;
    email: string;
    emailVerified: boolean;
  };
}

interface LoginUserFailAction {
  type: typeof LOGIN_FAIL;
  payload: {
    email: string;
    password: string;
    user: string;
  };
}

interface ResetErrorsAction {
  type: typeof RESET_AUTH_ERRORS;
  payload: {};
}

interface SignupUserAction {
  type: typeof SIGNUP_REQUEST;
  payload: {};
}

interface SignupUserSuccessAction {
  type: typeof SIGNUP_SUCCESS;
  payload: {
    uid: string;
    username?: string;
  };
}

interface SignupUserFailAction {
  type: typeof SIGNUP_FAIL;
  payload: {
    error: ReturnType<typeof firebaseErrsHandler>;
  };
}

export interface AuthState {
  uid: string;
  token: string;
  email: string;
  emailVerified: boolean;
  error: ReturnType<typeof firebaseErrsHandler>;
}

export type AuthActionTypes =
  | VerfiyEmailAction
  | LogoutUserAction
  | LoginUserAction
  | LoginUserSuccessAction
  | LoginUserFailAction
  | SignupUserAction
  | SignupUserSuccessAction
  | SignupUserFailAction
  | ResetErrorsAction;
