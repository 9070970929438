import firebase from 'firebase/app';
import Address from './[new]address';
import { Geoloc } from './[new]product';

export class ReviewIndex {
  totalAverage: number = 0.0;
  totalCount: number = 0;
  oneCount: number = 0;
  twoCount: number = 0;
  threeCount: number = 0;
  fourCount: number = 0;
  fiveCount: number = 0;

  constructor(data?: { [field: string]: any }) {
    this.totalAverage = data?.totalAverage ?? 0.0;
    this.totalCount = data?.totalCount ?? 0;
    this.oneCount = data?.oneCount ?? 0;
    this.twoCount = data?.twoCount ?? 0;
    this.threeCount = data?.threeCount ?? 0;
    this.fourCount = data?.fourCount ?? 0;
    this.fiveCount = data?.fiveCount ?? 0;
  }

  toJson(): ReviewIndex {
    return Object.assign({}, this);
  }
}

export class OpenDays {
  days: string;
  from: Date | null;
  to: Date | null;

  constructor(data?: { [field: string]: any }) {
    this.days = data?.days ?? '';
    this.from = data?.from
      ? typeof data?.from === 'object'
        ? data?.from instanceof firebase.firestore.Timestamp
          ? new Date(data?.from?.toDate())
          : new Date(data?.from)
        : new Date(data?.from?.toDate())
      : null;
    this.to = data?.to
      ? typeof data?.to === 'object'
        ? data?.to instanceof firebase.firestore.Timestamp
          ? new Date(data?.to.toDate())
          : new Date(data?.to)
        : new Date(data?.to.toDate())
      : null;
  }

  toJson(): OpenDays {
    return Object.assign({}, this);
  }
}

export class Shop {
  location: Omit<Address, 'name'> | null;
  description: string = '';
  shopLogo: string = '';
  phoneNumber: string = '';
  
  openDays: OpenDays[] = [];
  email: string = '';
  shopName: string = '';
  disabled: boolean = false;
  isAppointmentOnly: boolean = false;
  shopWebsiteLink: string = '';

  constructor(data?: { [field: string]: any }) {
    this.location = data?.location ?? null;
    this.description = data?.description ?? '';
    this.shopLogo = data?.shopLogo ?? '';
    this.phoneNumber = data?.phoneNumber ?? '';
    this.email = data?.email ?? '';
    if (data?.openDays) {
      this.openDays = [];
      data?.openDays?.forEach((b: any) => {
        this.openDays?.push(new OpenDays(b));
      });
    } else {
      this.openDays = [];
    }
    this.shopName = data?.shopName ?? '';
    this.disabled = data?.disabled ?? false;
    this.isAppointmentOnly = data?.isAppointmentOnly ?? false;
    this.shopWebsiteLink = data?.shopWebsiteLink ?? '';
  }

  toJson(): Shop {
    return Object.assign({}, this);
  }
}

export class SellerData {
  email: string = '';
  location: Omit<Address, 'name'> | null;
  phoneNumber: string = '';
  sellerName: string = '';
  showOnMap: boolean = true;
  websiteLink: string = '';

  constructor(data?: { [field: string]: any }) {
    this.email = data?.email ?? '';
    this.location = data?.location ?? null;
    this.phoneNumber = data?.phoneNumber ?? '';
    this.sellerName = data?.sellerName ?? '';
    this.showOnMap = data?.showOnMap ?? true;
    this.websiteLink = data?.websiteLink ?? '';
  }

  toJson(): SellerData {
    return Object.assign({}, this);
  }
}

export interface UserType {
  id?: string;
  uid?: string;
  userName?: string;
  firstName?: string;
  middleName?: string;
  websiteLink?: string;
  lastName?: string;
  profilePicture?: string;
  registeredSince: firebase.firestore.Timestamp;
  followersCount?: number;
  completionRate?: number;
  state?: string;
  city?: string;
  itemsSold?: number;
  itemsSelling?: number;
  itemsDraft?: number;
  paymentType?: number;
  sellerReviewIndex?: ReviewIndex | null;
  isGlobalUser: boolean;
  // recentlyViewedProducts: string[];
  userType?: string;
  shop?: Shop | null;
  _geoloc?: Geoloc | null;
  firstOne?: boolean;
  sellerData?: SellerData | null;
}

export class User implements UserType {
  id: string;
  uid: string = '';
  userName: string = '';
  firstName: string = '';
  middleName: string = '';
  websiteLink: string = '';
  lastName: string = '';
  profilePicture: string = '';
  registeredSince: firebase.firestore.Timestamp;
  followersCount: number;
  completionRate: number;
  state: string = '';
  city: string = '';
  itemsSold: number = 0;
  itemsSelling: number = 0;
  itemsDraft: number = 0;
  paymentType: number = -1;
  sellerReviewIndex: ReviewIndex | null = null;
  isGlobalUser: boolean = false;
  // recentlyViewedProducts: string[];
  userType: string = 'buyer';
  shop: Shop | null = null;
  _geoloc: Geoloc | null;
  firstOne: boolean = false;
  sellerData: SellerData | null = null;

  constructor(data: UserType) {
    this.id = data?.id ?? '';
    this.uid = data?.uid ?? '';
    this.userName = data?.userName ?? '';
    this.firstName = data?.firstName ?? '';
    this.middleName = data?.middleName ?? '';
    this.websiteLink = data?.websiteLink ?? '';
    this.lastName = data?.lastName ?? '';
    this.profilePicture = data?.profilePicture ?? '';
    this.registeredSince = data?.registeredSince;
    this.followersCount = data?.followersCount ?? 0;
    this.completionRate = data?.completionRate ?? 0;
    this.state = data?.state ?? '';
    this.city = data?.city ?? '';
    this.itemsSelling = data?.itemsSelling ?? 0;
    this.itemsSold = data?.itemsSold ?? 0;
    this.paymentType = data?.paymentType ?? -1;
    this.itemsDraft = data?.itemsDraft ?? 0;
    this.isGlobalUser=data?.isGlobalUser ?? false;
    this.sellerReviewIndex =
      data?.sellerReviewIndex !== null
        ? new ReviewIndex(data?.sellerReviewIndex)
        : new ReviewIndex();
    // this.recentlyViewedProducts = data.recentlyViewedProducts ?? [];
    this.userType = data?.userType ?? 'buyer';
    this.shop = data?.shop !== null ? new Shop(data?.shop) : null;
    // this._geoloc = data?._geoloc !==  null ? new Geoloc(data?._geoloc) : null;

    this._geoloc = data?._geoloc ?? null;
    this.firstOne = data?.firstOne ?? false;
    this.sellerData =
      data?.sellerData !== null ? new SellerData(data?.sellerData) : null;
  }

  toJson(): User {
    return Object.assign({}, this);
  }
}

export const userConverter: firebase.firestore.FirestoreDataConverter<
  Partial<UserType>
> = {
  toFirestore: (data: UserType): firebase.firestore.DocumentData => {
    return {
      ...data,
    };
  },
  fromFirestore: (
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ) => {
    const data = snapshot.data(options);
    return new User({
      ...(data as UserType),
    });
  },
};
