import { combineReducers } from 'redux';
import userReducer from './reducers/user';
import cartReducer from './reducers/cart';
import authReducer from './reducers/auth';
import uiReducer from './reducers/uiReducer';
import products from './reducers/products';
import { LOGOUT_USER } from './types/auth';

import searchPageReducer from './reducers/searchPage';
import wishList from './reducers/wishlist';
import addressReducer from './reducers/addresses';
import paymentReducer from './reducers/payment';
import checkoutReducer from './reducers/checkoutPage';
import postsReducer from './reducers/posts';
import commentsReducer from './reducers/comments';
import repliesReducer from './reducers/replies';
import selectedProductIdReducer from './reducers/selectedproductid'; // Updated import

import { UserActionTypes } from './types/user';
import { CartActionTypes } from './types/cart';
import { AuthActionTypes } from './types/auth';
import { ActionTypes as ProductsActionTypes } from './types/products';
import { ActionTypes as SearchActionTypes } from './types/search-page';
import { WishlistActionTypes } from './types/wishlist';
import { AddressActionTypes } from './types/addresses';
import { ActionTypes as PaymentActionTypes } from './types/payment';
import { ActionTypes as CheckoutActionTypes } from './types/CheckoutPage';
import { PostActionTypes } from './types/posts';
import { CommentActionTypes } from './types/comment';
import { ReplyActionTypes } from './types/reply';
import {SelectProductIdActionTypes}  from './types/selectproductid';

export type AllActionTypes =
  | UserActionTypes
  | CartActionTypes
  | AuthActionTypes
  | ProductsActionTypes
  | SearchActionTypes
  | WishlistActionTypes
  | AddressActionTypes
  | PaymentActionTypes
  | CheckoutActionTypes
  | PostActionTypes
  | CommentActionTypes
  | ReplyActionTypes
  | SelectProductIdActionTypes;

const reducers = combineReducers({
  cart: cartReducer,
  UI: uiReducer,
  auth: authReducer,
  user: userReducer,
  addresses: addressReducer,
  searchPage: searchPageReducer,
  payment: paymentReducer,
  // categories,
  products,
  wishList,
  checkoutPage: checkoutReducer,
  postsReducer,
  commentsReducer,
  repliesReducer,
  selectedProduct: selectedProductIdReducer, 

});

const rootReducer = (state: any, action: any) => {
  if (action.type === LOGOUT_USER) {
    state = undefined;
  }
  return reducers(state, action);
};

export type AppState = ReturnType<typeof reducers>;
export default rootReducer;
