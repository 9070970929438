import PrivateUser, { privateUserConverter } from '../../models/private-user';
import Address, { addressConverter } from '../../models/[new]address';
import { Something_Went_Wrong } from '../../utils/constants';
import {
  firestore,
  privateUserAddressesC,
  privateUsersC,
} from '../../utils/firebase-utils';
import { AppState } from '../root-reducer';
import { AppThunk } from '../store';
import {
  AddressActionTypes,
  ADD_ADDRESS_FAIL,
  ADD_ADDRESS_REQUEST,
  ADD_ADDRESS_SUCCESS,
  DELETE_ADDRESS_FAIL,
  DELETE_ADDRESS_REQUEST,
  DELETE_ADDRESS_SUCCESS,
  GET_ADDRESSES_FAIL,
  GET_ADDRESSES_REQUEST,
  GET_ADDRESSES_SUCCESS,
  SET_BILLING_ADDRESS_ID,
  SET_SHIPPING_ADDRESS_ID,
  UPDATE_ADDRESS_REQUEST,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_ADDRESS_FAIL,
} from '../types/addresses';
import { setUserThunk } from './user';

export const addAddressRequest = (): AddressActionTypes => {
  return { type: ADD_ADDRESS_REQUEST, payload: {} };
};

export const getAddressesRequest = (): AddressActionTypes => {
  return {
    type: GET_ADDRESSES_REQUEST,
    payload: {},
  };
};

export const getAddressesSuccess = ({
  addresses,
}: {
  addresses: Address[];
}): AddressActionTypes => {
  return {
    type: GET_ADDRESSES_SUCCESS,
    payload: {
      addresses,
    },
  };
};

export const getAddressesFail = ({
  error,
}: {
  error: string;
}): AddressActionTypes => {
  return {
    type: GET_ADDRESSES_FAIL,
    payload: {
      error,
    },
  };
};

export const addAddressSuccess = ({
  id,
  placeId,
  addressLineOne,
  name,
  addressLineTwo,
  country,
  state,
  suite,
  city,
  zipCode,
  lat,
  lng,
  isShipping = false,
  isBilling = false,
}: {
  id: string;
  placeId: string;
  addressLineOne: string;
  name: string;
  addressLineTwo: string;
  country: string;
  state: string;
  suite: string;
  city: string;
  zipCode: string;
  lat: number;
  lng: number;
  isShipping: boolean;
  isBilling: boolean;
}): AddressActionTypes => {
  return {
    type: ADD_ADDRESS_SUCCESS,
    payload: {
      id,
      placeId,
      addressLineOne,
      name,
      addressLineTwo,
      country,
      state,
      suite,
      city,
      zipCode,
      lat,
      lng,
      isShipping,
      isBilling,
    },
  };
};
export const addAddressFail = ({
  error,
}: {
  error: string;
}): AddressActionTypes => {
  return {
    type: ADD_ADDRESS_FAIL,
    payload: {
      error,
    },
  };
};

export const updateAddressRequest = (): AddressActionTypes => {
  return {
    type: UPDATE_ADDRESS_REQUEST,
    payload: {},
  };
};

export const updateAddressSuccess = ({
  id,
  placeId,
  addressLineOne,
  name,
  addressLineTwo,
  country,
  state,
  suite,
  city,
  zipCode,
  lat,
  lng,
  isShipping = false,
  isBilling = false,
}: {
  id: string;
  placeId: string;
  addressLineOne: string;
  name: string;
  addressLineTwo: string;
  country: string;
  state: string;
  suite: string;
  city: string;
  zipCode: string;
  lat: number;
  lng: number;
  isShipping: boolean;
  isBilling: boolean;
}): AddressActionTypes => {
  return {
    type: UPDATE_ADDRESS_SUCCESS,
    payload: {
      id,
      placeId,
      addressLineOne,
      name,
      addressLineTwo,
      country,
      state,
      suite,
      city,
      zipCode,
      lat,
      lng,
      isShipping,
      isBilling,
    },
  };
};

export const updateAddressFail = ({
  error,
  addressId,
}: {
  error: string;
  addressId: string;
}): AddressActionTypes => {
  return {
    type: UPDATE_ADDRESS_FAIL,
    payload: {
      error,
      addressId,
    },
  };
};

export const deleteAddressRequest = (): AddressActionTypes => {
  return {
    type: DELETE_ADDRESS_REQUEST,
    payload: {},
  };
};

export const deleteAddressSuccess = ({
  id,
}: {
  id: string;
}): AddressActionTypes => {
  return {
    type: DELETE_ADDRESS_SUCCESS,
    payload: { id },
  };
};

export const deleteAddressFail = ({
  id,
  error,
}: {
  id: string;
  error: string;
}): AddressActionTypes => {
  return {
    type: DELETE_ADDRESS_FAIL,
    payload: { id, error },
  };
};

export type AddAddressThunkParams = Address & {
  isShipping?: boolean;
  isBilling?: boolean;
};

export const addAddressThunk = ({
  addressLineOne,
  addressLineTwo,
  country,
  state,
  suite,
  city,
  zipCode,
  placeId,
  name,
  lat,
  lng,
  isBilling = false,
  isShipping = false,
}: Address & {
  isShipping?: boolean;
  isBilling?: boolean;
}): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(addAddressRequest());
    try {
      const emptyDoc = await firestore.collection('private_users').doc();
      await privateUserAddressesC(uid)
        .withConverter(addressConverter)
        .doc(emptyDoc.id)
        .set(
          {
            addressLineOne,
            addressLineTwo,
            country,
            state,
            suite,
            city,
            zipCode,
            placeId,
            name,
            lat,
            lng,
            id: emptyDoc.id,
          },
          { merge: true },
        );

      let options: any = {};
      if (isShipping || isBilling) {
        if (isShipping) options.defaultShippingAddress = emptyDoc.id;
        if (isBilling) options.defaultBillingAddress = emptyDoc.id;

        await privateUsersC
          .doc(uid)
          .withConverter(privateUserConverter)
          .set(options, { merge: true });
      }

      dispatch(
        addAddressSuccess({
          addressLineOne,
          addressLineTwo: addressLineTwo ?? '',
          country,
          state,
          suite,
          city,
          zipCode,
          lat,
          lng,
          placeId,
          name,
          id: emptyDoc.id,
          isShipping,
          isBilling,
        }),
      );
      return emptyDoc.id;
    } catch (e) {
      console.log(e);
      dispatch(addAddressFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

export const updateAddressThunk = ({
  id,
  name,
  isBilling = false,
  isShipping = false,
  cb = () => {},
}: Address & {
  isShipping?: boolean;
  isBilling?: boolean;
  cb: () => void;
}): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(updateAddressRequest());
    try {
      const address = await privateUserAddressesC(uid)
        .doc(id as any)
        .withConverter(addressConverter)
        .set(
          {
            name,
          },
          {
            merge: true,
          },
        );
      const selectedAddress = await privateUserAddressesC(uid)
        .doc(id as any)
        .withConverter(addressConverter)
        .get();
      const user = await privateUsersC
        .doc(uid)
        .withConverter(privateUserConverter)
        .get();

      let options: any = {};
      if (isShipping || isBilling) {
        if (isShipping) options.defaultShippingAddress = id;
        if (isBilling) options.defaultBillingAddress = id;

        await privateUsersC
          .doc(uid)
          .withConverter(privateUserConverter)
          .set(options, { merge: true });
      }

      dispatch(
        updateAddressSuccess({
          addressLineOne: selectedAddress.data()?.addressLineOne as any,
          addressLineTwo: selectedAddress.data()?.addressLineTwo as any,
          country: selectedAddress.data()?.country as any,
          state: selectedAddress.data()?.state as any,
          suite: selectedAddress.data()?.suite as any,
          city: selectedAddress.data()?.city as any,
          zipCode: selectedAddress.data()?.zipCode as any,
          lat: selectedAddress.data()?.lat as any,
          lng: selectedAddress.data()?.lng as any,
          placeId: selectedAddress.data()?.placeId as any,
          name: selectedAddress.data()?.name as any,
          id: id as any,
          isShipping,
          isBilling,
        }),
      );
      if (cb) {
        cb();
      }
    } catch (e) {
      console.log(e);
      dispatch(
        updateAddressFail({
          error: e.message || Something_Went_Wrong,
          addressId: id as any,
        }),
      );
    }
  };
};

export const getAddressesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      auth: { uid },
    } = getState();
    dispatch(getAddressesRequest());
    try {
      const addressesRef = await privateUserAddressesC(uid)
        .withConverter(addressConverter)
        .get();
      const addresses = addressesRef.docs.map(doc => doc.data());
      dispatch(getAddressesSuccess({ addresses }));
    } catch (e) {
      dispatch(getAddressesFail({ error: e.message || Something_Went_Wrong }));
    }
  };
};

export const setShippingAddressId = ({
  id,
}: {
  id: string;
}): AddressActionTypes => {
  return {
    type: SET_SHIPPING_ADDRESS_ID,
    payload: {
      id,
    },
  };
};

export const setBillingAddressId = ({
  id,
}: {
  id: string;
}): AddressActionTypes => {
  return {
    type: SET_BILLING_ADDRESS_ID,
    payload: {
      id,
    },
  };
};

export const shippingAddressSelector = (state: AppState) => {
  const addressesArray = state.addresses.addresses;
  const addressId = state.addresses.defaultShippingAddress;
  return addressesArray.find(address => address.id === addressId);
};

export const billingAddressSelector = (state: AppState) => {
  const addressesArray = state.addresses.addresses;
  const addressId = state.addresses.defaultBillingAddress;
  return addressesArray.find(address => address.id === addressId);
};

export const deleteAddressThunk = ({ id }: { id: string }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(deleteAddressRequest());

    try {
      const {
        auth: { uid },
        addresses: { defaultBillingAddress, defaultShippingAddress },
      } = getState();

      const options: Partial<PrivateUser> = {};
      if (id === defaultBillingAddress) {
        options.defaultBillingAddress = '';
      }

      if (id === defaultShippingAddress) {
        options.defaultShippingAddress = '';
      }

      if (Object.keys(options).length > 0)
        await privateUsersC
          .doc(uid)
          .withConverter(privateUserConverter)
          .set(options, {
            merge: true,
          });

      await privateUserAddressesC(uid).doc(id).delete();
      dispatch(deleteAddressSuccess({ id }));
    } catch (e) {
      dispatch(
        deleteAddressFail({ id, error: e.messsage || Something_Went_Wrong }),
      );
    }
  };
};

// export const addAddressRequest = (): AddressActionTypes => {
//   return { type: ADD_ADDRESS_REQUEST, payload: {} };
// };
// export const addAddressRequest = (): AddressActionTypes => {
//   return { type: ADD_ADDRESS_REQUEST, payload: {} };
// };
// export const addAddressRequest = (): AddressActionTypes => {
//   return { type: ADD_ADDRESS_REQUEST, payload: {} };
// };
// export const addAddressRequest = (): AddressActionTypes => {
//   return { type: ADD_ADDRESS_REQUEST, payload: {} };
// };
// export const addAddressRequest = (): AddressActionTypes => {
//   return { type: ADD_ADDRESS_REQUEST, payload: {} };
// };
