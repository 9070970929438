import {
  ProductsState,
  ActionTypes,
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
} from '../types/products';

const initState: ProductsState = {
  list: [],
  error: '',
};

const reducer = (state = initState, action: ActionTypes): ProductsState => {
  if (action.type === GET_PRODUCTS_REQUEST) {
    return state;
  } else if (action.type === GET_PRODUCTS_SUCCESS) {
    const { list } = action.payload;
    const { list: oldList } = state;
    return {
      ...state,
      list: [...oldList, ...list],
      error: '',
    };
  } else if (action.type === GET_PRODUCTS_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  }
  return state;
};

export default reducer;
