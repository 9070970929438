import React from 'react';
import {
  makeStyles,
  createStyles,
  Theme,
  IconButton,
  IconButtonProps,
  Badge,
  Tooltip,
} from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      padding: '0 !important',
      maxWidth: 'min-content',
      minWidth: 'min-content',
      margin: '0 0.5rem',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    appBar: {
      background: '#fff',
    },
    whiteBadgeText: {
      color: '#fff',
    },
  }),
);

type Props = {
  content?: React.ReactNode;
  btnProps?: IconButtonProps;
  children: React.ReactNode;
  className?: string;
  variant?: string;
  invisible?: boolean;
  tooltipTitle?: any;
};

const BadgeIconBtn = ({
  content,
  children,
  btnProps,
  className,
  variant,
  invisible,
  tooltipTitle,
}: Props) => {
  const classes = useStyles();

  return (
    <IconButton className={clsx(classes.root, className)} {...btnProps}>
      <Tooltip title={tooltipTitle} arrow>
        {variant && variant === 'dot' ? (
          <Badge
            classes={{
              badge: classes.whiteBadgeText,
            }}
            color="secondary"
            variant={'dot'}
            invisible={invisible}
          >
            {children}
          </Badge>
        ) : (
          <Badge
            classes={{
              badge: classes.whiteBadgeText,
            }}
            color="secondary"
            badgeContent={content}
          >
            {children}
          </Badge>
        )}
      </Tooltip>
    </IconButton>
  );
};

export default BadgeIconBtn;
