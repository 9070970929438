import { Something_Went_Wrong } from '../../utils/constants';
import {
  ADD_WISHLIST_ITEM_FAIL,
  ADD_WISHLIST_ITEM_REQUEST,
  ADD_WISHLIST_ITEM_SUCCESS,
  GET_WISHLIST_FAIL,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_SUCCESS,
  REMOVE_WISHLIST_ITEM_FAIL,
  REMOVE_WISHLIST_ITEM_REQUEST,
  REMOVE_WISHLIST_ITEM_SUCCESS,
  WishlistActionTypes,
  WishlistState,
} from '../types/wishlist';

const initState: WishlistState = {
  list: [],
  error: '',
};

const reducer = (
  state = initState,
  action: WishlistActionTypes,
): WishlistState => {
  if (action.type === ADD_WISHLIST_ITEM_REQUEST) {
  } else if (action.type === ADD_WISHLIST_ITEM_SUCCESS) {
    const { id } = action.payload;
    const { list } = state;
    return { ...state, error: '', list: [id, ...list] };
  } else if (action.type === ADD_WISHLIST_ITEM_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error: error || Something_Went_Wrong,
    };
  } else if (action.type === GET_WISHLIST_REQUEST) {
    return state;
  } else if (action.type === GET_WISHLIST_SUCCESS) {
    const { list } = action.payload;
    return {
      ...state,
      error: '',
      list,
    };
  } else if (action.type === GET_WISHLIST_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error: error ?? Something_Went_Wrong,
    };
  } else if (action.type === REMOVE_WISHLIST_ITEM_REQUEST) {
    return state;
  } else if (action.type === REMOVE_WISHLIST_ITEM_SUCCESS) {
    const { id } = action.payload;
    const { list } = state;
    return {
      ...state,
      error: '',
      list: list.filter(item => item !== id),
    };
  } else if (action.type === REMOVE_WISHLIST_ITEM_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error: error ?? Something_Went_Wrong,
    };
  }
  return state;
};

export default reducer;
