import LightingImg from '../assets/categories/lighting.jpg';
import VintageClothingImg from '../assets/categories/vintage-clothing.jpg';
import FurnitureImg from '../assets/categories/furniture.jpg';
import ClockImg from '../assets/categories/clock.jpg';
import BooksImg from '../assets/categories/books.jpg';
import GlasswareImg from '../assets/categories/glassware.jpg';
import PotteryImg from '../assets/categories/pottery.jpg';
import RugsImg from '../assets/categories/rugs.jpg';
import IndustrialImg from '../assets/categories/industry.jpg';
import AdvertisingImg from '../assets/categories/advertisement.jpg';
import ScientificImg from '../assets/categories/science.jpg';
import ArtworkImg from '../assets/categories/artwork.jpg';
import Others from '../assets/categories/others.png';
import Lighting from '../assets/categories/lighting.jpg';
import VintageClothing from '../assets/categories/vintage-clothing.jpg';
import Furniture from '../assets/categories/furniture.jpg';
import Clock from '../assets/categories/clock.jpg';
import Books from '../assets/categories/books.jpg';
import Glassware from '../assets/categories/glassware.jpg';
import Pottery from '../assets/categories/pottery.jpg';
import Rugs from '../assets/categories/rugs.jpg';
import Industrial from '../assets/categories/industry.jpg';
import Advertising from '../assets/categories/advertisement.jpg';
import Scientific from '../assets/categories/science.jpg';
import Artwork from '../assets/categories/artwork.jpg';

export interface Category {
  name: string;
  image: string;
  url: string;
}

export const legacyCategories: Category[] = [
  {
    name: 'Clocks and Timepieces',
    image: Clock,
    url: '/collections/Clock&Timepieces',
  },
  {
    name: 'Furniture',
    image: Furniture,
    url: '/collections/Furniture',
  },
  {
    name: 'Lighting',
    image: Lighting,
    url: '/collections/Lighting',
  },
  {
    name: 'Books',
    image: Books,
    url: '/collections/Books',
  },
  {
    name: 'Glassware',
    image: Glassware,
    url: '/collections/Glassware',
  },
  {
    name: 'Vintage Clothing',
    image: VintageClothing,
    url: '/collections/VintageClothing',
  },
  {
    name: 'Scientific',
    image: Scientific,
    url: '/collections/Scientific',
  },
  {
    name: 'Pottery',
    image: Pottery,
    url: '/collections/Pottery',
  },
  {
    name: 'Rugs',
    image: Rugs,
    url: '/collections/Rugs',
  },
  {
    name: 'Industrial',
    image: Industrial,
    url: '/collections/Industrial',
  },
  {
    name: 'Advertising',
    image: Advertising,
    url: '/collections/Advertising',
  },
  {
    name: 'Artwork',
    image: Artwork,
    url: '/collections/Artwork',
  },
];

export const getCategoryImage = (category: string) => {
  legacyCategories.forEach(cat => {
    if (cat.name === category) return cat.image;
  });
  return 'null';
};

export const categories = {
  advertising: {
    name: 'Advertising',
    displayName: 'Advertising',
    image: AdvertisingImg,
    url: '/categories/Advertising',
  },
  artwork: {
    name: 'Artwork',
    displayName: 'Artwork',
    image: ArtworkImg,
    url: '/categories/Artwork',
  },
  nooks: {
    name: 'Books',
    displayName: 'Books',
    image: BooksImg,
    url: '/categories/Books',
  },
  clockAndTimepieces: {
    name: 'Clocks and Timepieces',
    displayName: 'Clock & Timepieces',
    image: ClockImg,
    url: '/categories/Clock&Timepieces',
  },
  furniture: {
    name: 'Furniture',
    displayName: 'Furniture',
    image: FurnitureImg,
    url: '/categories/Furniture',
  },
  glassware: {
    name: 'Glassware',
    displayName: 'Glassware',
    image: GlasswareImg,
    url: '/categories/Glassware',
  },
  industrial: {
    name: 'Industrial',
    displayName: 'Industrial',
    image: IndustrialImg,
    url: '/categories/Industrial',
  },
  lighting: {
    name: 'Lighting',
    displayName: 'Lighting',
    image: LightingImg,
    url: '/categories/Lighting',
  },
  pottery: {
    name: 'Pottery',
    displayName: 'Pottery',
    image: PotteryImg,
    url: '/categories/Pottery',
  },
  rugs: {
    name: 'Rugs',
    displayName: 'Rugs',
    image: RugsImg,
    url: '/categories/Rugs',
  },
  scientific: {
    name: 'Scientific',
    displayName: 'Scientific',
    image: ScientificImg,
    url: '/categories/Scientific',
  },
  vintageClothing: {
    name: 'Vintage Clothing',
    displayName: 'Vintage Clothing',
    image: VintageClothingImg,
    url: '/categories/VintageClothing',
  },
  others: {
    name: 'Others',
    displayName: 'Others',
    image: Others,
    url: '/categories/Others',
  },
};

export const brainTreeAuthKey = 'sandbox_bn8fxppq_37r8hh82xrz7tfn4';

export const Something_Went_Wrong = 'Something went wrong';
export const USERNAME_TAKEN_ERROR = 'Username is taken, please try another';

export const MAX_STR_LENGTH_CART_MENU = 44;
export const Max_Str_Length = {};
export const GoogleApiKey = 'AIzaSyBNLK6vMMagQ_LsrxqGCcVvzWO4sJb09cY';
export const GoogleLocationSearchLink = `https://www.mapquestapi.com/geocoding/v1/reverse?key=${GoogleApiKey}&location=`;

// export const STRIPE_KEY =
//   'pk_test_51KmnyiF1A2OdzfVhcub8EiXEaC7bbbkLuT0Kxcyr2N0eIlmmt7XOi0esB4pFFV0W8lKc3Wu2H8ToqtnynHFFiGEd00KrAYR1BY';
// prod-stripe-key
// export const STRIPE_KEY =
//   'pk_live_51KmnyiF1A2OdzfVhrd34OKKT9FVskxpOnjsekxRIuCH5INSdjFjcobFLXNZ1kcPkIMWDSU7kDhyQajE9kxYMxRn300JL7Epe6d';

export let STRIPE_KEY: string;

if (process.env.REACT_APP_ENV === 'production') {
  STRIPE_KEY =
    'pk_live_51MnNhfFU9lbZjSZwKDGGANphe8TxGzALjMONTfVYgHoHpjNy03gzEZhBFd1iRiOUr3EBxDD4Ueu9aTk6ddeQBk8B00mbaZhIzf';
} else {
  STRIPE_KEY =
    'pk_test_51MnNhfFU9lbZjSZwaE8MzCIE0ly7WLrkhpDTECsdHOmaMsrq464z5j5puPpCqAmuBZ8BGSIde9Co6VT9ctrFjDqX00aGN2f0AH';
}
