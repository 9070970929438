import React, { useEffect, useState, useRef } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SmsIcon from '@material-ui/icons/Sms';
import SendIcon from '@material-ui/icons/Send';
import CameraAltIcon from '@material-ui/icons/CameraAlt';
import {
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
} from '@material-ui/core';
import moment from 'moment';
import { useTypedSelector } from '../../redux/store';
import { useParams, useHistory } from 'react-router';
import {
  addCommentThunk,
  getCommentsThunk,
} from '../../redux/actions/comments';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import comonSnackOpts from '../../utils/snackbar';
import { Carousel } from 'react-responsive-carousel';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { likePostThunk, dislikePostThunk } from '../../redux/actions/posts';
import BackspaceIcon from '@material-ui/icons/Backspace';
import CircularLoader from '../../components/CircularProgressBar/CircularLoader';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import EditPost from './components/EditPost';
import Comment from './components/Comment';
import { Formik } from 'formik';
import { postsC } from '../../utils/firebase-utils';
import Post, { postConverter } from '../../models/[new]post';
import firebase from 'firebase';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '60%',
      margin: '3rem auto',
      [theme.breakpoints.down('xs')]: {
        width: '94%',
      },
    },
    avatar: {
      backgroundColor: red[500],
      cursor: 'pointer',
    },
    commentInput: {
      display: 'flex',
      height: '2.5rem',
      padding: '0.5rem 0.75rem',
      alignItems: 'center',
      gap: '0.5rem',
      flex: '1 0 0',
      borderRadius: '4.36763rem',
      border: '1.092px solid #EEE',
      background: '#FFF',
      margin: '0 1rem',
      [theme.breakpoints.down('xs')]: {
        margin: '0 .5rem',
      },
    },
    imgsContainer: {
      display: 'flex',
      justifyContent: 'center',
      margin: '.1rem 0 ',
    },
    commentImg: {
      width: '100px',
      height: '100px',
      marginRight: '10px',
      borderRadius: '10px',
      [theme.breakpoints.down('xs')]: {
        width: '55px',
        height: '55px',
      },
    },
    commentsContainer: {
      margin: 'auto',
      display: 'flex',
      width: '60%',
      padding: '1rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: '0.875rem',
      borderRadius: '0.5rem',
      background: '#FFF',
      [theme.breakpoints.down('xs')]: {
        width: '95%',
        padding: 'unset',
      },
    },
    commentsTitle: {
      fontWeight: 'bold',
      fontFamily: 'Poppins',
      fontSize: '1rem',
    },
    postImageFeaturedStar: {
      position: 'absolute',
      top: -15,
      right: -10,
      color: '#B87A4E',
      opacity: 1,
    },
  }),
);

export default function RecipeReviewCard() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useTypedSelector(state => state.user);
  const { uid } = useTypedSelector(state => state.user);
  const { commentsList } = useTypedSelector(state => state.commentsReducer);
  const { postsList } = useTypedSelector(state => state.postsReducer);
  const { id } = useParams<{ id: string }>();
  const [commentText, setCommentText] = useState('');
  const [imageFiles, setImageFiles] = useState<any>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [editModal, setEditModal] = useState(false);
  const getCommentsLoading = useTypedSelector(
    state => state.UI.loaders.GET_COMMENTS,
  );
  const [showFullPost, setShowFullPost] = useState(false);
  const [singlePost, setSinglePost] = useState<Post | null>(null);
  const [totalReplies, setTotalReplies] = useState(0);
  const postRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const fetchSinglePost = async () => {
      try {
        const postRef = await postsC.doc(id).withConverter(postConverter).get();
        const postData = postRef.data() as Post | undefined;
        if (postData) {
          setSinglePost(postData);
        } else {
          console.error('Post data not found');
        }
      } catch (error) {
        console.error('Error fetching single post:', error);
      }
    };

    fetchSinglePost();
  }, [id]);

  // const togglePostVisibility = () => {
  //   setShowFullPost(!showFullPost);
  // };

  const togglePostVisibility = () => {
    setShowFullPost(showFullPost => !showFullPost);
    if ( postRef.current && showFullPost ) {
      postRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCommentsThunk(id));
    }
  }, [dispatch, id]);

  const fetchComments = () => {
    dispatch(getCommentsThunk(id));
  };

  useEffect(() => {
    const repliesCount = (commentsList || []).reduce((acc, comment) => {
      return acc + (comment.repliesCount || 0);
    }, 0);
    setTotalReplies(repliesCount);
  }, [commentsList]);

  const handleImageChange = (e: any) => {
    const selectedFiles = Array.from(e.target.files);
    if (
      imageFiles?.length + selectedFiles?.length > 5 ||
      selectedFiles?.length > 5
    ) {
      enqueueSnackbar("Images can't be more than 5", {
        variant: 'error',
        ...comonSnackOpts,
      });
      return;
    }
    setImageFiles((prevFiles: any) => [...prevFiles, ...selectedFiles]);
  };

  const handleCameraClick = () => {
    // Trigger the file input click
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = (
    image: Blob | MediaSource,
    index: React.Key | null | undefined,
  ) => {
    if (!image) {
      return;
    }
    const removedFromImages = imageFiles?.filter((item: any) => {
      return item != image;
    });
    setImageFiles(removedFromImages);
  };

  const handleComment = async (values: any) => {
    // Check if the value contains only spaces
    const containsOnlySpaces = /^\s+$/.test(commentText);

    if (containsOnlySpaces) {
      enqueueSnackbar(
        'Please ensure that the comment does not consist solely of spaces.',
        {
          variant: 'error',
          ...comonSnackOpts,
        },
      );
    } else {
      try {
        await dispatch(
          addCommentThunk({
            postID: id,
            images: imageFiles,
            content: commentText,
            uid: user?.uid,
            profilePicture: user?.profilePicture?.url,
            userDisplayName:
              (user?.userType === 'seller' && user
                ? user?.sellerData?.sellerName
                : user?.userType === 'shop' && user
                ? user?.shop?.shopName
                : user?.userType === 'buyer' && user
                ? user?.firstName
                : '') ?? '',
          }),
        );
        fetchComments();
        enqueueSnackbar('Comment added successfully', {
          variant: 'success',
          ...comonSnackOpts,
        });
        setCommentText('');
        setImageFiles([]);
      } catch (e) {
        enqueueSnackbar('Something went wrong', {
          variant: 'error',
          ...comonSnackOpts,
        });
      }
    }
  };

  const handleLikePost = async () => {
    if (singlePost?.likedByIDs?.includes(user?.uid)) {
      await dispatch(dislikePostThunk(id));
    } else {
      await dispatch(likePostThunk(id));
    }
  };

  return (
    <>
      <Card className={classes.root} ref={postRef}>
        <CardHeader
          avatar={
            <Avatar
              aria-label="recipe"
              className={classes.avatar}
              src={singlePost?.profilePicture}
              onClick={() => history.push(`/profile/${singlePost?.uid}`)}
            >
              {singlePost?.userDisplayName?.[0]}
            </Avatar>
          }
          action={
            singlePost?.uid === user?.uid ? (
              <IconButton
                aria-label="settings"
                onClick={() => setEditModal(true)}
              >
                <EditOutlinedIcon />
              </IconButton>
            ) : (
              ''
            )
          }
          title={singlePost?.userDisplayName}
          subheader={
            singlePost?.createdAt
              ? moment(new Date(singlePost?.createdAt.seconds * 1000)).fromNow()
              : 'just now'
          }
        />
        <CardContent>
          <Typography variant="body2" color="textPrimary" component="p" >
            {showFullPost
              ? singlePost?.content
              : singlePost?.content?.length && singlePost.content.length > 200
              ? `${singlePost?.content?.slice(0, 200)}...`
              : singlePost?.content}
          </Typography>
          {singlePost?.content?.length && singlePost.content.length > 200 && (
            <Button
              onClick={togglePostVisibility}
              style={{
                fontWeight: 'bold',
                color: 'rgb(152, 69, 0)',
                textTransform: 'capitalize',
              }}
            >
              {showFullPost ? 'Read Less' : 'Read More'}
            </Button>
          )}
        </CardContent>
        <Carousel dynamicHeight swipeable={false}>
          {singlePost?.images?.map((img, index) => {
            return (
              <div>
                <img src={img} alt={`pic ${index}`} />
              </div>
            );
          })}
        </Carousel>

        <CardActions disableSpacing>
          {uid && (
            <IconButton aria-label="add to favorites" onClick={handleLikePost}>
              {singlePost?.likedByIDs?.includes(user?.uid) ? (
                <FavoriteIcon style={{ color: '#E74040' }} />
              ) : (
                <FavoriteBorderIcon style={{ color: '#E74040' }} />
              )}
              {singlePost?.likesCount}
            </IconButton>
          )}
          <IconButton
            aria-label="share"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '8px',
            }}
          >
            <SmsIcon />
            <span style={{ marginLeft: '2px', marginBottom: '0px' }}>
              {/* {commentsList?.length} */}
              {commentsList?.length + totalReplies} 
            </span>
          </IconButton>
        </CardActions>
        {uid && (
          <CardActions>
            <Formik
              enableReinitialize={true}
              initialValues={{
                comment: '',
              }}
              onSubmit={handleComment}
            >
              {({ handleSubmit, setFieldValue }) => (
                <form
                  onSubmit={handleSubmit}
                  style={{
                    display: 'flex',
                    width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Avatar
                    aria-label="recipe"
                    className={classes.avatar}
                    src={user?.profilePicture?.url}
                  >
                    {user?.userType === 'seller'
                      ? user?.sellerData?.sellerName?.[0]
                      : user?.userType === 'shop'
                      ? user?.shop?.shopName?.[0]
                      : user?.firstName?.[0]}
                  </Avatar>
                  <input
                    placeholder="Add a comment"
                    className={classes.commentInput}
                    value={commentText}
                    onChange={e => setCommentText(e.target.value)}
                    name="comment"
                  />
                  <div>
                    <IconButton
                      type="submit"
                      disabled={
                        commentText === '' && imageFiles?.length === 0
                          ? true
                          : false
                      }
                    >
                      <SendIcon />
                    </IconButton>
                    <IconButton onClick={handleCameraClick}>
                      <CameraAltIcon />
                    </IconButton>
                  </div>
                </form>
              )}
            </Formik>
          </CardActions>
        )}
        <div className={classes.imgsContainer}>
          <input
            type="file"
            id="imageUpload"
            name="imageUpload"
            onChange={handleImageChange}
            multiple
            accept="image/*"
            ref={fileInputRef}
            hidden
          />
          <div style={{ display: 'flex' }}>
            {imageFiles?.map(
              (
                file: Blob | MediaSource,
                index: React.Key | null | undefined,
              ) => (
                <div
                  role="button"
                  tabIndex={0}
                  style={{ position: 'relative' }}
                  key={`img : ${index}`}
                >
                  <IconButton
                    onClick={() => handleRemoveImage(file, index)}
                    className={classes.postImageFeaturedStar}
                    style={{ zIndex: '9999' }}
                  >
                    <BackspaceIcon />
                  </IconButton>
                  <img
                    key={index}
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                    className={classes.commentImg}
                  />
                </div>
              ),
            )}
          </div>
        </div>
      </Card>
      {commentsList?.length > 0 ? (
        <Card className={classes.commentsContainer}>
          <h1 className={classes.commentsTitle}>Comments</h1>
          {commentsList?.map(c => {
            return <Comment comment={c} postID={id} />;
          })}
        </Card>
      ) : (
        ''
      )}
      <EditPost
        setEditModal={setEditModal}
        editModal={editModal}
        post={singlePost}
      />
    </>
  );
}
