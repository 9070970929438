import React from 'react';
import CustomButton, { Props as CustomButtonProps } from './Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

const CheckoutBtn = (props: CustomButtonProps) => {
  return (
    <CustomButton
      startIcon={<ArrowForwardIcon />}
      variant="contained"
      size="large"
      color="primary"
      {...props}
    >
      Checkout
    </CustomButton>
  );
};
export default CheckoutBtn;
