import { CartItem } from '../models/[new]product';

interface AddParams {
  type: 'ADD';
  total: number;
  cart: CartItem[];
  productId: string;
  price: number;
  quantity: number;
  isLocalPickup: boolean;
  // isSold: boolean;
}

interface DeleteParams {
  type: 'DELETE';
  cart: CartItem[];
  total: number;
  price: number;
  productId: string;
}

interface UpdateParams {
  type: 'UPDATE';
  cart: CartItem[];
  productId: string;
  newQuantity: number;
  price: number | null;
  total: number;
}

export const calcCart = (params: AddParams | DeleteParams | UpdateParams) => {
  if (params.type === 'ADD') {
    const { total, productId, cart, price, quantity, isLocalPickup } = params;

    const proExists = cart.find(pro => pro.id === productId);

    const clonedPro = proExists ? { ...proExists } : undefined;

    const incrementValue = price * quantity;

    let newCart = [];

    if (clonedPro) {
      (clonedPro as CartItem).quantity += quantity;
      newCart = [
        {
          id: clonedPro.id,
          quantity: clonedPro.quantity,
          isLocalPickup: clonedPro.isLocalPickup,
          companyIndex: clonedPro.companyIndex,
        },
        ...cart.filter(pro => pro.id !== productId),
      ];
    } else {
      newCart = [
        ...cart,
        {
          quantity,
          id: productId,
          isLocalPickup: isLocalPickup,
          companyIndex: isLocalPickup ? -1 : 0,
        },
      ];
    }
    return {
      total: total + incrementValue,
      cart: newCart,
      // cartEmpty
    };
  } else if (params.type === 'DELETE') {
    const { productId, price, cart, total } = params;

    const selectedProduct = cart.find(pro => pro.id === productId);

    if (!selectedProduct) return { cart, total };

    const decrementValue = selectedProduct.quantity * price;
    const newCart = cart.filter(pro => pro.id !== productId);

    return {
      total: total - decrementValue,
      cart: newCart,
    };
  } else if (params.type === 'UPDATE') {
    const { total, price, productId, cart, newQuantity } = params;

    let newTotal = total;

    const newCart = cart.map(pro => {
    //   if (pro.id === productId) {
    //     newTotal = total - price * pro.quantity + price * newQuantity;
    //     return {
    //       ...pro,
    //       quantity: newQuantity,
    //     };
    //   }
    //   return pro;
    // });

    if (pro.id === productId) {
      if (
        typeof price === 'number' &&
        typeof pro.quantity === 'number' &&
        typeof newQuantity === 'number'
      ) {
        newTotal = total - price * pro.quantity + price * newQuantity;
        return {
          ...pro,
          quantity: newQuantity,
        };
      } else {
        // Handle the case where any of the values are not valid numbers
        throw new Error('Invalid value: price, quantity, or newQuantity is not a number');
      }
    }
    return pro;
  });

    return {
      total: newTotal,
      cart: newCart,
    };
  } else return { total: 0, cart: [] };
};

// if (operator === '+') {
//   const newCart = cart.map(pro => {
//     if (pro.id === productId) {
//       return {
//         ...pro,
//         quantity: pro.quantity + quantity,
//       };
//     }
//     return pro;
//   });

//   return {
//     total: total + quantity * price,
//     cart: newCart,
//   };
// } else if (operator === '-') {
//   const newCart = cart.map(pro => {
//     if (pro.id === productId) {
//       return {
//         ...pro,
//         quantity: pro.quantity - quantity,
//       };
//     }
//     return pro;
//   });

//   return {
//     total: total - quantity * price,
//     cart: newCart,
//   };
// } else return { total, cart };
