import React from 'react';
import clsx from 'clsx';

import {
  CircularProgress,
  CircularProgressProps,
  makeStyles,
  createStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      display: 'block',
      margin: 'auto',
    },
  }),
);

const MenuLoader = ({ className, ...rest }: CircularProgressProps) => {
  const classes = useStyles();
  return (
    <CircularProgress
      className={clsx(classes.root, className)}
      color="primary"
      thickness={4}
      {...rest}
    />
  );
};
const QurtarLoader = ({ className, ...rest }: CircularProgressProps) => {
  const classes = useStyles();

  return (
    <CircularProgress
      className={clsx(classes.root, className)}
      color="primary"
      size="4rem"
      thickness={4}
      {...rest}
    />
  );
};
const PageLoader = ({ className, ...rest }: CircularProgressProps) => {
  const classes = useStyles();

  return (
    <CircularProgress
      className={clsx(classes.root, className)}
      color="primary"
      size="5rem"
      thickness={4}
      {...rest}
    />
  );
};

export default {
  MenuLoader,
  PageLoader,
  QurtarLoader,
};
