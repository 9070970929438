import { Product } from '../../models/[new]product';

export const GET_PRODUCTS_REQUEST = 'GET_PRODUCTS_REQUEST';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

// TODO
// export const ADD_PRODUCT_REQUEST = 'ADD_PRODUCT_REQUEST';
// export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
// export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';

export interface GetProductsRequest {
  type: typeof GET_PRODUCTS_REQUEST;
  payload: {};
}
export interface GetProductsSuccess {
  type: typeof GET_PRODUCTS_SUCCESS;
  payload: { list: Product[] };
}
export interface GetProductsFail {
  type: typeof GET_PRODUCTS_FAIL;
  payload: { error: string };
}

export type ActionTypes =
  | GetProductsRequest
  | GetProductsSuccess
  | GetProductsFail;

export interface ProductsState {
  list: Product[];
  error: string;
}
