import { calcCart } from '../../utils/calcCart';

import {
  ADD_CART_ITEM_FAIL,
  ADD_CART_ITEM_REQUEST,
  ADD_CART_ITEM_SUCCESS,
  CartState,
  CLEAR_CART_FAIL,
  CLEAR_CART_REQUEST,
  CLEAR_CART_SUCCESS,
  DELETE_CART_ITEM_FAIL,
  DELETE_CART_ITEM_REQUEST,
  DELETE_CART_ITEM_SUCCESS,
  DELETE_ITEM_SHIPPMENT,
  GET_CART_ITEMS_FAIL,
  GET_CART_ITEMS_REQUEST,
  GET_CART_ITEMS_SUCCESS,
  RESET_CART_ERROR,
  SET_ITEM_SHIPPMENT,
  SET_TOTAL,
  UPDATE_CART_ITEM_FAIL,
  UPDATE_CART_ITEM_REQUEST,
  UPDATE_CART_ITEM_SUCCESS,
  UPDATE_ITEM_SHIPPMENT,
  SUBMIT_ORDER_REQUEST,
  SUBMIT_ORDER_SUCCESS,
  SUBMIT_ORDER_FAIL,
} from '../types/cart';

import { CartActionTypes } from '../types/cart';

const initState: CartState = {
  error: '',
  products: [], // legacy don't use
  cart: [],
  total: 0,
  lastFetch: Date.now(),
  cartShippment: [],
  // bag
  // products
};

const cartReducer = (state = initState, action: CartActionTypes): CartState => {
  if (action.type === RESET_CART_ERROR) {
    return {
      ...state,
      error: '',
    };
  }
  if (action.type === CLEAR_CART_REQUEST) {
    return {
      ...state,
      error: '',
    };
  } else if (action.type === CLEAR_CART_SUCCESS) {
    return {
      ...state,
      error: '',
      total: 0,
      cart: [],
    };
  } else if (action.type === CLEAR_CART_FAIL) {
    return {
      ...state,
      error: action.payload.error,
    };
  } else if (action.type === ADD_CART_ITEM_REQUEST) {
    return {
      ...state,
    };
  } else if (action.type === ADD_CART_ITEM_SUCCESS) {
    const { id, quantity, price, isLocalPickup } = action.payload;

    const { cart, total } = calcCart({
      type: 'ADD',
      cart: state.cart,
      total: state.total,
      price: price ?? 0,
      quantity,
      productId: id,
      isLocalPickup,
      // isSold,
    });
    return {
      ...state,
      error: '',
      cart,
      total,
    };
  } else if (action.type === ADD_CART_ITEM_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === DELETE_CART_ITEM_REQUEST) {
    return { ...state };
  } else if (action.type === DELETE_CART_ITEM_SUCCESS) {
    const { id, price } = action.payload;

    const { total, cart } = calcCart({
      type: 'DELETE',
      productId: id,
      price: price ?? 0,
      cart: state.cart,
      total: state.total,
    });

    return {
      ...state,
      total,
      cart,
    };
  } else if (action.type === DELETE_CART_ITEM_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === UPDATE_CART_ITEM_SUCCESS) {
    const { price, newQuantity, id } = action.payload;

    const { total, cart } = calcCart({
      type: 'UPDATE',
      price,
      productId: id,
      newQuantity,
      total: state.total,
      cart: state.cart,
    });

    return {
      ...state,
      total,
      cart,
    };
  } else if (action.type === UPDATE_CART_ITEM_FAIL) {
    return {
      ...state,
      error: action.payload.error,
    };
  } else if (action.type === GET_CART_ITEMS_SUCCESS) {
    const { list } = action.payload;
    return {
      ...state,
      cart: list,
      cartShippment: list.map(item => {
        return {
          productId: item.id,
          companyIndex: item.companyIndex,
          isLocalPickup: item.isLocalPickup,
          quantity: item.quantity,
        };
      }),
    };
  } else if (action.type === GET_CART_ITEMS_FAIL) {
    const { error } = action.payload;
    return { ...state, error };
  } else if (action.type === SET_ITEM_SHIPPMENT) {
    const { id, companyIndex, isLocalPickup } = action.payload;
    return {
      ...state,
      cartShippment: [
        ...state.cartShippment,
        { productId: id, companyIndex, isLocalPickup },
      ],
    };
  } else if (action.type === DELETE_ITEM_SHIPPMENT) {
    const { id } = action.payload;
    const newShippmentArr = state.cartShippment.filter(
      item => item.productId !== id,
    );
    return {
      ...state,
      cartShippment: newShippmentArr,
    };
  } else if (action.type === UPDATE_ITEM_SHIPPMENT) {
    const { companyIndex, id, isLocalPickup } = action.payload;
    // @ts-ignore
    if (companyIndex >= 0) {
      const newShippmentArr = state.cartShippment.map(item =>
        item.productId === id
          ? { ...item, companyIndex, isLocalPickup: false }
          : item,
      );

      return {
        ...state,
        // @ts-ignore
        cartShippment: newShippmentArr,
      };
    } else if (isLocalPickup) {
      const newShippmentArr = state.cartShippment.map(item =>
        item.productId === id
          ? { ...item, isLocalPickup: true, companyIndex: -1 }
          : item,
      );

      return {
        ...state,
        cartShippment: newShippmentArr,
      };
    }
  } else if (action.type === SET_TOTAL) {
    const { total } = action.payload;
    return {
      ...state,
      total,
    };
  } else if (action.type === SUBMIT_ORDER_SUCCESS) {
    return {
      ...state,
      error: '',
    };
  } else if (action.type === SUBMIT_ORDER_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  }
  return state;
};

export default cartReducer;
