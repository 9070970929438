import {
  ActionTypes,
  ADD_CARD_FAIL,
  ADD_CARD_REQUEST,
  ADD_CARD_SUCCESS,
  ADD_PAYPAL_FAIL,
  ADD_PAYPAL_REQUEST,
  ADD_PAYPAL_SUCCESS,
  DELETE_CARD_FAIL,
  DELETE_CARD_REQUEST,
  DELETE_CARD_SUCCESS,
  GET_CARDS_FAIL,
  GET_CARDS_REQUEST,
  GET_CARDS_SUCCESS,
  GET_PAYPAL_FAIL,
  GET_PAYPAL_REQUEST,
  GET_PAYPAL_SUCCESS,
  StateType,
  DELETE_PAYPAL_REQUEST,
  DELETE_PAYPAL_SUCCESS,
  DELETE_PAYPAL_FAIL,
} from '../types/payment';

const initState: StateType = {
  cards: {
    list: [],
    error: '',
  },
  paypal: {
    error: '',
    account: null,
  },
};

const reducer = (state = initState, action: ActionTypes): StateType => {
  if (action.type === GET_CARDS_REQUEST) {
    return state;
  } else if (action.type === GET_CARDS_SUCCESS) {
    const { cards } = action.payload;
    return {
      ...state,
      cards: {
        error: '',
        list: [...cards],
      },
    };
  } else if (action.type === GET_CARDS_FAIL) {
    const { error } = action.payload;
    const {
      cards: { list },
    } = state;
    return {
      ...state,
      cards: {
        list,
        error,
      },
    };
  } else if (action.type === ADD_CARD_REQUEST) {
    return { ...state, cards: { ...state.cards, error: '' } };
  } else if (action.type === ADD_CARD_SUCCESS) {
    const {
      cards: { list: oldList },
    } = state;
    const { card } = action.payload;
    return {
      ...state,
      cards: {
        list: [...oldList, card],
        error: '',
      },
    };
  } else if (action.type === ADD_CARD_FAIL) {
    const { error } = action.payload;

    return {
      ...state,
      cards: {
        ...state.cards,
        error,
      },
    };
  } else if (action.type === DELETE_CARD_REQUEST) {
    return state;
  } else if (action.type === DELETE_CARD_SUCCESS) {
    const { id } = action.payload;
    const {
      cards: { list },
    } = state;
    const newList = list.filter(item => item.id !== id);
    return {
      ...state,
      cards: {
        list: newList,
        error: '',
      },
    };
  } else if (action.type === DELETE_CARD_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      cards: {
        ...state.cards,
        error,
      },
    };
  } else if (action.type === ADD_PAYPAL_REQUEST) {
    return state;
  } else if (action.type === ADD_PAYPAL_SUCCESS) {
    const { account } = action.payload;
    return {
      ...state,
      paypal: {
        error: '',
        account,
      },
    };
  } else if (action.type === ADD_PAYPAL_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      paypal: {
        ...state.paypal,
        error,
      },
    };
  } else if (action.type === GET_PAYPAL_REQUEST) {
    return state;
  } else if (action.type === GET_PAYPAL_SUCCESS) {
    const { account } = action.payload;
    return {
      ...state,
      paypal: {
        error: '',
        account,
      },
    };
  } else if (action.type === GET_PAYPAL_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      paypal: {
        account: null,
        error,
      },
    };
  } else if (action.type === DELETE_PAYPAL_REQUEST) {
    return state;
  } else if (action.type === DELETE_PAYPAL_SUCCESS) {
    return {
      ...state,
      paypal: { account: null, error: '' },
    };
  } else if (action.type === DELETE_PAYPAL_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      paypal: { ...state.paypal, error },
    };
  }
  return state;
};

export default reducer;
