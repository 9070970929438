import { AllActionTypes } from '../root-reducer';
import {
  ADD_CART_ITEM_REQUEST,
  ADD_CART_ITEM_SUCCESS,
  ADD_CART_ITEM_FAIL,
} from '../types/cart';
import {
  ADD_WISHLIST_ITEM_REQUEST,
  ADD_WISHLIST_ITEM_SUCCESS,
  ADD_WISHLIST_ITEM_FAIL,
} from '../types/wishlist';
import {
  SEARCH_REQUEST,
  SEARCH_SUCCESS,
  SEARCH_FAIL,
  SEARCH_SHOPS_REQUEST,
  SEARCH_SHOPS_SUCCESS,
} from '../types/search-page';

interface Loaders {
  [x: string]: {
    loading: boolean;
    [x: string]: any;
  };
}

// as a map of maps, because it's faster and the refrencing will have no complexity

type CartLoaders = {
  [x: string]: {
    loading: boolean;
    error?: string;
  };
};

type WishlistLoaders = {
  [x: string]: {
    loading: boolean;
    error?: string;
  };
};

interface State {
  loaders: {
    [x: string]: boolean;
  };
  cartItemsLoaders: CartLoaders;
  wishListItemsLoaders: WishlistLoaders;
}

const initState: State = {
  loaders: { SEARCH: true, SEARCH_SHOP: true },
  cartItemsLoaders: {},
  wishListItemsLoaders: {},
};

const uiReducer = (state = initState, action: AllActionTypes): State => {
  const { type } = action;
  const matches = /(.*)_(REQUEST|SUCCESS|FAIL)/.exec(type);

  if (matches) {
    const [_, requestPrefix, requestState] = matches;

    if (
      action.type === ADD_CART_ITEM_REQUEST ||
      action.type === ADD_CART_ITEM_SUCCESS ||
      action.type === ADD_CART_ITEM_FAIL
    ) {
      const { cartItemsLoaders } = state;

      const { id, error } = action.payload as { id: string; error: string };
      return {
        ...state,
        cartItemsLoaders: {
          [id]: {
            ...cartItemsLoaders[id],
            loading: requestState === 'REQUEST',
            error: error ?? '',
          },
        },
      };
    } else if (
      action.type === ADD_WISHLIST_ITEM_REQUEST ||
      action.type === ADD_WISHLIST_ITEM_SUCCESS ||
      action.type === ADD_WISHLIST_ITEM_FAIL
    ) {
      const { wishListItemsLoaders } = state;
      const { id, error } = action.payload as { id: string; error: string };
      return {
        ...state,
        wishListItemsLoaders: {
          [id]: {
            ...wishListItemsLoaders[id],
            loading: requestState === 'REQUEST',
            error: error ?? '',
          },
        },
      };
    }

    return {
      ...state,
      loaders: {
        ...state.loaders,
        [requestPrefix]: requestState === 'REQUEST',
        SEARCH: action.type === SEARCH_SUCCESS ? false : true,
        SEARCH_SHOPS: action.type === SEARCH_SHOPS_SUCCESS ? false : true,
      },
    };
  }
  return state;
};

export default uiReducer;
