import Address from '../../models/[new]address';

export const ADD_ADDRESS_REQUEST = 'ADD_ADDRESS_REQUEST';
export const ADD_ADDRESS_SUCCESS = 'ADD_ADDRESS_SUCCESS';
export const ADD_ADDRESS_FAIL = 'ADD_ADDRESS_FAIL';

export const UPDATE_ADDRESS_REQUEST = 'UPDATE_ADDRESS_REQUEST';
export const UPDATE_ADDRESS_SUCCESS = 'UPDATE_ADDRESS_SUCCESS';
export const UPDATE_ADDRESS_FAIL = 'UPDATE_ADDRESS_FAIL';

export const DELETE_ADDRESS_REQUEST = 'DELETE_ADDRESS_REQUEST';
export const DELETE_ADDRESS_SUCCESS = 'DELETE_ADDRESS_SUCCESS';
export const DELETE_ADDRESS_FAIL = 'DELETE_ADDRESS_FAIL';

export const GET_BILLING_ADDRESS_REQUEST = 'GET_BILLING_ADDRESS_REQUEST';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_FAIL = 'GET_BILLING_ADDRESS_FAIL';

export const GET_ADDRESSES_REQUEST = 'GET_ADDRESSES_REQUEST';
export const GET_ADDRESSES_SUCCESS = 'GET_ADDRESSES_SUCCESS';
export const GET_ADDRESSES_FAIL = 'GET_ADDRESSES_FAIL';

export const SET_BILLING_ADDRESS_ID = 'SET_BILLING_ADDRESS_ID';
export const SET_SHIPPING_ADDRESS_ID = 'SET_SHIPPING_ADDRESS_ID';

// export const REMOVE_BILLING_ADDRESS_REQUEST = 'REMOVE_BILLING_ADDRESS_REQUEST';
// export const REMOVE_BILLING_ADDRESS_SUCCESS = 'REMOVE_BILLING_ADDRESS_SUCCESS';
// export const REMOVE_BILLING_ADDRESS_FAIL = 'REMOVE_BILLING_ADDRESS_FAIL';

// export const ADD_SHIPPING_ADDRESS_REQUEST = 'ADD_SHIPPING_ADDRESS_REQUEST';
// export const ADD_SHIPPING_ADDRESS_SUCCESS = 'ADD_SHIPPING_ADDRESS_SUCCESS';
// export const ADD_SHIPPING_ADDRESS_FAIL = 'ADD_SHIPPING_ADDRESS_FAIL';

// export const REMOVE_SHIPPING_ADDRESS_REQUEST =
//   'REMOVE_SHIPPING_ADDRESS_REQUEST';
// export const REMOVE_SHIPPING_ADDRESS_SUCCESS =
//   'REMOVE_SHIPPING_ADDRESS_SUCCESS';
// export const REMOVE_SHIPPING_ADDRESS_FAIL = 'REMOVE_SHIPPING_ADDRESS_FAIL';

interface SetBillingAddressId {
  type: typeof SET_BILLING_ADDRESS_ID;
  payload: {
    id: string;
  };
}
interface SetShippingAddressId {
  type: typeof SET_SHIPPING_ADDRESS_ID;
  payload: {
    id: string;
  };
}

interface AddAddressRequest {
  type: typeof ADD_ADDRESS_REQUEST;
  payload: {};
}

interface AddAddressSuccess {
  type: typeof ADD_ADDRESS_SUCCESS;
  payload: {
    id: string;
    type?: 'billing' | 'shipping' | 'none';
    placeId: string;
    addressLineOne: string;
    name: string;
    addressLineTwo: string;
    country: string;
    state: string;
    suite: string;
    city: string;
    zipCode: string;
    lat: number;
    lng: number;
    isBilling?: boolean;
    isShipping?: boolean;
  };
}

interface AddAddressFail {
  type: typeof ADD_ADDRESS_FAIL;
  payload: {
    error: string;
  };
}

interface UpdateAddressRequest {
  type: typeof UPDATE_ADDRESS_REQUEST;
  payload: {};
}

interface UpdateAddressSuccess {
  type: typeof UPDATE_ADDRESS_SUCCESS;
  payload: {
    id: string;
    type?: 'billing' | 'shipping' | 'none';
    placeId: string;
    addressLineOne: string;
    name: string;
    addressLineTwo: string;
    country: string;
    state: string;
    suite: string;
    city: string;
    zipCode: string;
    lat: number;
    lng: number;
    isBilling?: boolean;
    isShipping?: boolean;
  };
}

interface UpdateAddressFail {
  type: typeof UPDATE_ADDRESS_FAIL;
  payload: {
    error: string;
    addressId: string;
  };
}

interface DeleteAddressRequest {
  type: typeof DELETE_ADDRESS_REQUEST;
  payload: {};
}

interface DeleteAddressSuccess {
  type: typeof DELETE_ADDRESS_SUCCESS;
  payload: {
    id: string;
  };
}
interface DeleteAddressFail {
  type: typeof DELETE_ADDRESS_FAIL;
  payload: {
    id: string;
    error: string;
  };
}

interface GetAddressesRequest {
  type: typeof GET_ADDRESSES_REQUEST;
  payload: {};
}

interface GetAddressesSuccess {
  type: typeof GET_ADDRESSES_SUCCESS;
  payload: {
    addresses: Address[];
  };
}

interface GetAddressesFail {
  type: typeof GET_ADDRESSES_FAIL;
  payload: {
    error: string;
  };
}

// interface AddBillingAddressSuccess {
//   type: typeof ADD_BILLING_ADDRESS_SUCCESS;
//   payload: {};
// }

// interface AddBillingAddressFail {
//   type: typeof ADD_BILLING_ADDRESS_FAIL;
//   payload: {
//     error: string;
//   };
// }

// interface RemoveBillingAddressRequest {
//   type: typeof REMOVE_BILLING_ADDRESS_REQUEST;
// }

// interface RemoveBillingAddressSuccess {
//   type: typeof REMOVE_BILLING_ADDRESS_SUCCESS;
//   payload: {};
// }

// interface RemoveBillingAddressFail {
//   type: typeof REMOVE_BILLING_ADDRESS_FAIL;
//   payload: {
//     error: string;
//   };
// }

// interface AddShippingAddressRequest {
//   type: typeof ADD_SHIPPING_ADDRESS_REQUEST;
// }

// interface AddShippingAddressSuccess {
//   type: typeof ADD_SHIPPING_ADDRESS_SUCCESS;
//   payload: {};
// }

// interface AddShippingAddressFail {
//   type: typeof ADD_SHIPPING_ADDRESS_FAIL;
//   payload: {
//     error: string;
//   };
// }

// interface RemoveShippingAddressRequest {
//   type: typeof REMOVE_SHIPPING_ADDRESS_REQUEST;
// }

// interface RemoveShippingAddressSuccess {
//   type: typeof REMOVE_SHIPPING_ADDRESS_SUCCESS;
//   payload: {};
// }

// interface RemoveShippingAddressFail {
//   type: typeof REMOVE_SHIPPING_ADDRESS_FAIL;
//   payload: {
//     error: string;
//   };
// }

export type AddressActionTypes =
  | AddAddressRequest
  | AddAddressSuccess
  | AddAddressFail
  | UpdateAddressRequest
  | UpdateAddressSuccess
  | UpdateAddressFail
  | DeleteAddressRequest
  | DeleteAddressSuccess
  | DeleteAddressFail
  | GetAddressesRequest
  | GetAddressesSuccess
  | GetAddressesFail
  | SetShippingAddressId
  | SetBillingAddressId;

export interface State {
  addresses: Address[];
  error: string;
  defaultShippingAddress: string;
  defaultBillingAddress: string;
}
