// types/selectproductid.ts
export const SELECT_PRODUCT_ID_REQUEST = 'SELECT_PRODUCT_ID_REQUEST';
export const SELECT_PRODUCT_ID_SUCCESS = 'SELECT_PRODUCT_ID_SUCCESS';
export const SELECT_PRODUCT_ID_FAIL = 'SELECT_PRODUCT_ID_FAIL';
export const SELECT_PRODUCT_ID = 'SELECT_PRODUCT_ID';

export interface SelectProductIdRequestAction {
  type: typeof SELECT_PRODUCT_ID_REQUEST;
}

export interface SelectProductIdSuccessAction {
  type: typeof SELECT_PRODUCT_ID_SUCCESS;
  payload: { productId: string };
}

export interface SelectProductIdFailAction {
  type: typeof SELECT_PRODUCT_ID_FAIL;
  payload: { error: string };
}

export type SelectProductIdActionTypes =
  | SelectProductIdRequestAction
  | SelectProductIdSuccessAction
  | SelectProductIdFailAction;
