import firebase from 'firebase/app';
import PrivateUser, { privateUserConverter } from '../../models/private-user';
import { Product } from '../../models/[new]product';
import { Something_Went_Wrong } from '../../utils/constants';
import { privateUsersC } from '../../utils/firebase-utils';
import { AppState } from '../root-reducer';
import { AppThunk } from '../store';
import {
  ADD_WISHLIST_ITEM_FAIL,
  ADD_WISHLIST_ITEM_REQUEST,
  ADD_WISHLIST_ITEM_SUCCESS,
  REMOVE_WISHLIST_ITEM_REQUEST,
  WishlistActionTypes,
  REMOVE_WISHLIST_ITEM_SUCCESS,
  REMOVE_WISHLIST_ITEM_FAIL,
  GET_WISHLIST_REQUEST,
  GET_WISHLIST_SUCCESS,
  GET_WISHLIST_FAIL,
} from '../types/wishlist';
import { getProductsThunk } from './products';

export const addWishListItemRequest = (): WishlistActionTypes => {
  return {
    type: ADD_WISHLIST_ITEM_REQUEST,
    payload: {},
  };
};

export const addWishListItemSuccess = ({
  id,
}: {
  id: string;
}): WishlistActionTypes => {
  return { type: ADD_WISHLIST_ITEM_SUCCESS, payload: { id } };
};

export const addWishListItemFail = ({
  error,
}: {
  error?: string;
}): WishlistActionTypes => {
  return {
    type: ADD_WISHLIST_ITEM_FAIL,
    payload: { error },
  };
};

export const removeWishListItemRequest = (): WishlistActionTypes => {
  return {
    type: REMOVE_WISHLIST_ITEM_REQUEST,
    payload: {},
  };
};

export const removeWishlistItemSuccess = ({
  id,
}: {
  id: string;
}): WishlistActionTypes => {
  return {
    type: REMOVE_WISHLIST_ITEM_SUCCESS,
    payload: {
      id,
    },
  };
};
export const removeWishListItemFail = ({
  error,
}: {
  error: string;
}): WishlistActionTypes => {
  return {
    type: REMOVE_WISHLIST_ITEM_FAIL,
    payload: {
      error,
    },
  };
};

export const getWishListRequest = (): WishlistActionTypes => {
  return {
    type: GET_WISHLIST_REQUEST,
    payload: {},
  };
};

export const getWishListSuccess = ({
  list,
}: {
  list: string[];
}): WishlistActionTypes => {
  return {
    type: GET_WISHLIST_SUCCESS,
    payload: {
      list,
    },
  };
};
export const getWishListFail = ({
  error,
}: {
  error: string;
}): WishlistActionTypes => {
  return {
    type: GET_WISHLIST_FAIL,
    payload: {
      error,
    },
  };
};

export const getWishListThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(getWishListRequest());
    const {
      auth: { uid },
    } = getState();
    try {
      const data = (
        await privateUsersC.withConverter(privateUserConverter).doc(uid).get()
      ).data() as PrivateUser;
      if (!data) {
        return dispatch(getWishListFail({ error: Something_Went_Wrong }));
      }
      const { wishList } = data;
      dispatch(getWishListSuccess({ list: wishList }));
      dispatch(getProductsThunk({ ids: wishList }));
    } catch (e) {
      dispatch(getWishListFail({ error: e.message || e.code }));
    }
  };
};

export const deleteWishlistedItemThunk = ({ id }: { id: string }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(removeWishListItemRequest());
    const {
      auth: { uid },
    } = getState();
    try {
      privateUsersC
        .withConverter(privateUserConverter)
        .doc(uid)
        .set(
          {
            wishList: firebase.firestore.FieldValue.arrayRemove(id) as any,
          },
          { merge: true },
        );
      dispatch(removeWishlistItemSuccess({ id }));
    } catch (e) {
      dispatch(removeWishListItemFail({ error: e.message || e.code }));
    }
  };
};

export const addWishlistItemThunk = ({ id }: { id: string }): AppThunk => {
  return async (dispatch, getState) => {
    dispatch(addWishListItemRequest());
    const {
      auth: { uid },
    } = getState();
    try {
      privateUsersC
        .withConverter(privateUserConverter)
        .doc(uid)
        .set(
          {
            wishList: firebase.firestore.FieldValue.arrayUnion(id) as any,
          },
          { merge: true },
        );
      dispatch(addWishListItemSuccess({ id }));
      dispatch(getProductsThunk({ ids: [id] }));
      return id;
    } catch (e) {
      dispatch(addWishListItemFail({ error: e.message || e.code }));
    }
  };
};

export const getWishlitedProductsSelector = (state: AppState) => {
  const {
    products: { list: products },
    wishList: { list: wishList },
  } = state;

  const wishlistedProducts = wishList
    .map(productId => {
      const pro = products.find(product => product.id === productId);
      if (!pro) return undefined;
      return { ...pro };
    })
    .filter(e => e);
  return wishlistedProducts as Product[];
};
