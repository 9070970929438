import {
  CommentActionTypes,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAIL,
  GET_COMMENTS_FAIL,
  GET_COMMENTS_REQUEST,
  GET_COMMENTS_SUCCESS,
  LIKE_COMMENT_REQUEST,
  LIKE_COMMENT_SUCCESS,
  LIKE_COMMENT_FAIL,
  DISLIKE_COMMENT_FAIL,
  DISLIKE_COMMENT_REQUEST,
  DISLIKE_COMMENT_SUCCESS,
  State,
} from '../types/comment';

const initState: State = {
  commentsList: [],
  error: '',
};

const reducer = (state = initState, action: CommentActionTypes): State => {
  if (action.type === ADD_COMMENT_REQUEST) {
    return state;
  } else if (action.type === ADD_COMMENT_SUCCESS) {
    const { commentsList } = state;
    return {
      ...state,
      error: '',
      commentsList: [...commentsList, action.payload],
    };
  } else if (action.type === ADD_COMMENT_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === GET_COMMENTS_REQUEST) {
    return state;
  } else if (action.type === GET_COMMENTS_SUCCESS) {
    const { commentsList } = action.payload;
    return {
      ...state,
      commentsList,
    };
  } else if (action.type === GET_COMMENTS_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === LIKE_COMMENT_REQUEST) {
    return state;
  } else if (action.type === LIKE_COMMENT_SUCCESS) {
    const { commentsList } = state;
    return {
      ...state,
      commentsList,
      error: '',
    };
  } else if (action.type === LIKE_COMMENT_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  } else if (action.type === DISLIKE_COMMENT_REQUEST) {
    return state;
  } else if (action.type === DISLIKE_COMMENT_SUCCESS) {
    const { commentsList } = state;
    return {
      ...state,
      commentsList,
      error: '',
    };
  } else if (action.type === DISLIKE_COMMENT_FAIL) {
    const { error } = action.payload;
    return {
      ...state,
      error,
    };
  }

  return state;
};

export default reducer;
