import { createStore, applyMiddleware, Store, Action } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import { TypedUseSelectorHook, useSelector } from 'react-redux';

import { composeWithDevTools } from 'redux-devtools-extension';
import RootReducer, { AppState } from './root-reducer';

import { loginRequest } from './actions/auth';
const middlewares = [thunk];

const store: Store = createStore(
  RootReducer,
  composeWithDevTools({
    trace: true,
  })(applyMiddleware(...middlewares)),
);

store.dispatch(loginRequest());

export type AppThunk = ThunkAction<void, AppState, unknown, Action<string>>;
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;
export default store;
