import firebase from 'firebase/app';
interface AddressType {
  id?: string;
  placeId: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state: string;
  suite: string;
  country: string;
  zipCode: string;
  lat: number;
  lng: number;
  name: string;
}

export default class Address implements AddressType {
  id?: string;
  placeId: string;
  addressLineOne: string;
  addressLineTwo?: string;
  city: string;
  state: string;
  suite: string;
  country: string;
  zipCode: string;
  lat: number;
  lng: number;
  name: string;

  constructor(data: AddressType) {
    this.id = data?.id ?? '';
    this.placeId = data?.placeId ?? '';
    this.addressLineOne = data?.addressLineOne ?? '';
    this.addressLineTwo = data?.addressLineTwo ?? '';
    this.city = data?.city ?? '';
    this.state = data?.state ?? '';
    this.suite = data?.state ?? '';
    this.country = data?.country ?? '';
    this.zipCode = data?.zipCode ?? '';
    this.lat = data?.lat;
    this.lng = data?.lng;
    this.name = data?.name ?? '';
  }

  // get primaryLine() {
  //   return this.addressLineOne;
  // }

  // get secondaryLine() {
  //   return this.city + ', ' + this.state + ', ' + this.country;
  // }

  // get secondaryLineWithZipCode() {
  //   return (
  //     this.city + ' - ' + this.zipCode + ', ' + this.state + ', ' + this.country
  //   );
  // }

  // toJson(): Address {
  //   return Object.assign({}, this);
  // }
}

export const addressConverter: firebase.firestore.FirestoreDataConverter<Address> =
  {
    toFirestore: (
      address: Partial<Address>,
    ): firebase.firestore.DocumentData => {
      return {
        ...address,
      };
    },
    fromFirestore: (
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ) => {
      const data = snapshot.data(options) as Address;
      return new Address({
        id: snapshot.id,
        ...(data as Address),
      });
    },
  };
